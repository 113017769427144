@import "../../scss/variables-mixin.scss";

.manage-ads-content {
  .manage-ads-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    @include border-radius(5px);
    @include box-shadow(0px, 2px, 10px, rgba($black, 0.1));
    .manage-ads-card-media {
      min-height: 115px;
      background-size: cover;
      background-position: center center;
    }
    .manage-ads-h3 {
      font-size: 1rem;
      font-family: "ralewaybold";
    }
    .manage-ads-h5 {
      font-size: 0.75rem;
      font-family: "ralewayregular";
      margin-bottom: 14px;
      color: rgba($black, 0.4);
    }
    .manage-ads-h4 {
      font-size: 0.875rem;
      font-family: "ralewayregular";
      color: $primary-color;
    }
    .manage-ads-h6 {
      font-size: 0.813rem;
      font-family: "ralewayregular";
      margin-bottom: 14px;
      color: rgba($black, 0.4);
    }
    .manage-ads-p {
      font-size: 0.75rem;
      font-family: "ralewayregular";
    }
    .manage-ads-active {
      font-family: "ralewaybold";
      font-size: 0.75rem;
      color: $success-dark-color;
    }
    .manage-ads-title-info {
      font-size: 0.813rem;
      font-family: "ralewayregular";
      padding: 0 9px;
      color: rgba($black, 0.4);
    }
    .manage-ads-rank-number {
      font-size: 0.875rem;
      font-family: "ralewaybold";
      text-align: right;
      padding: 5px 9px;
    }
    .manage-ads-rank-text {
      font-size: 0.813rem;
      font-family: "ralewayregular";
      text-align: right;
      padding: 5px 9px;
      color: rgba($black, 0.4);
    }
    .manage-ads-action-bar {
      border-top: rgba($black, 0.1) 1px solid;
      padding: 5px 0;
      margin: 0 5px;
    }
    &:hover {
      .manage-ads-actions {
        display: inline-block;
      }
    }
    .manage-ads-actions {
      position: absolute;
      top: 10px;
      right: 10px;
      display: none;
      button {
        background: $white;
        color: $primary-color;
        width: 22px;
        height: 22px;
        min-width: 22px;
        min-height: 22px;
        @include border-radius(50%);
        font-size: 10px;
        margin-left: 4px;
        &:hover {
          background: $primary-color;
          color: $white;
        }
      }
      .manage-ad-delete {
        background-color: $danger-color;
        color: $white;
        &:hover {
          background: $danger-hover-color;
          color: $white;
        }
      }
    }
  }
  .manage-ads-head-container {
    padding: 10px 0;
    margin-bottom: 20px;
    .manage-ads-head-left {
      @media (max-width: 1280px) {
        text-align: center;
        .page-title {
          padding-bottom: 20px;
        }
      }
    }
    .manage-ads-head-right {
      text-align: right;
      @media (max-width: 1280px) {
        text-align: center;
      }
    }
    .page-head {
      display: inline-block;
    }
  }
  .page-head {
    @media (max-width: 600px) {
      width: 100%;
      .page-search-box {
        width: 100%;
      }
    }
  }
}
.manage-ads-popup-details {
  .manage-ads-popup-details-head {
    position: absolute;
    right: 0;
  }
  .manage-ad-details-img {
    border: red 2px solid;
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
    overflow: hidden;
    @media (max-width: 1280px) {
      max-height: 350px;
    }
    img {
      width: 100%;
      height: 100%;
      vertical-align: middle;
      object-fit: cover;
      object-position: center;
    }
  }
}
.manage-ads-details-action-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0;
  border-top: rgba($black, 0.1) 1px solid;
  margin-top: 20px;
  .manage-ads-details-action-bar-left {
    flex-grow: 1;
  }
  .manage-ads-details-action-bar-right {
    flex-grow: 1;
    text-align: right;
  }
  .manage-ads-active {
    font-family: "ralewaybold";
    font-size: 0.75rem;
    padding: 2px 0px;
    color: $success-dark-color;
  }
  .manage-ads-title-info {
    font-size: 0.813rem;
    font-family: "ralewayregular";
    padding: 2px 0px;
    color: rgba($black, 0.4);
  }
  .manage-ads-rank-number {
    font-size: 0.875rem;
    font-family: "ralewaybold";
    text-align: right;
    padding: 2px 0px;
  }
  .manage-ads-rank-text {
    font-size: 0.813rem;
    font-family: "ralewayregular";
    text-align: right;
    padding: 2px 0px;
    color: rgba($black, 0.4);
  }
}
.manage-ad-details {
  padding: 20px 40px;
  .manage-ads-h3 {
    font-size: 1.125rem;
    font-family: "ralewaybold";
  }
  .manage-ads-h5 {
    font-size: 0.875rem;
    font-family: "ralewayregular";
    margin-bottom: 14px;
    color: rgba($black, 0.4);
  }
  .manage-ads-h4 {
    font-size: 1rem;
    font-family: "ralewayregular";
    color: $primary-color;
  }
  .manage-ads-h6 {
    font-size: 0.875rem;
    font-family: "ralewayregular";
    margin-bottom: 14px;
    color: $black;
  }
  .manage-ads-p {
    font-size: 0.875rem;
    font-family: "ralewayregular";
  }
}
.manage-ads-details-buttons {
  display: flex;
  margin-top: 10px;
  justify-content: right;
  .MuiButton-root {
    height: 32px !important;
    min-width: auto !important;
    line-height: 100% !important;
    margin-left: 10px;
  }
}
