@import "../../../scss/variables-mixin.scss";
.add-member-content {
  padding: 50px;
  @media (max-width:600px)
  {
    padding: 40px 20px;
  }
  .add-member-heading {
    font-size: 16px;
    margin: 70px auto 40px auto;
  }
  .form-container {
    padding: 20px 0;
  }
  .button-container {
    padding: 30px 0;
    @media (max-width:600px)
    {
      text-align: center;
      .MuiButtonBase-root
      {
        min-width: 120px;
      }
    }
  }
  .user-image-add-preview-container {
    display: flex;
    .user-image-preview {
      width: 56px;
      height: 56px;
      display: flex;
      @include border-radius(20px);
      border: rgba(0, 0, 0, 0.3) 1px solid;
      overflow: hidden;
      img {
        object-fit: cover;
      }
    }
    label {
      flex: 1 1 auto;
      width: 1%;
      margin-left: 10px;
    }
  }
}
.member-loader {
  position: absolute;
  width: 100%;
  background: rgba(255, 255, 255, 0.9);
  z-index: 3;
  height: 100%;
  box-sizing: border-box;
  .document-loader {
    position: sticky;
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    > span {
      padding-left: 0 !important;
      display: inline-flex;
    }
  }
}
