@import "../../scss/variables-mixin.scss";

.documents-grid {
  padding: 0px 0px 20px;
  .documents-grid-box {
    @include border-radius(5px);
    @include box-shadow(0px, 2px, 10px, rgba($black, 0.1));
    background-color: $white;
    padding: 5px 15px;
    position: relative;
    text-align: center;

    .documents-grid-actions {
      text-align: right;
      padding: 5px 0;
      z-index: 1;
      position: relative;
      display: flex;

      > div {
        flex-grow: 1;
        &:first-child {
          text-align: left;
        }
        &:last-child {
          text-align: right;
        }
      }
      .doc-grid-action-menu {
        padding: 0;
        @include border-radius(0px);
        .pen-icon {
          margin: 0 7px 0 7px;
        }
        .trash-icon {
          margin: 0 9px 0 6px;
        }
        .eye-icon {
          margin: 0 7px 0 5px;
        }
        .eye-icon:hover {
          color: "red";
        }
        li {
          font-size: 0.75rem;
          font-weight: bold;
          border-bottom: rgba($black, 0.05) 1px solid;
          padding: 5px 15px 5px 10px;
          &:last-child {
            border-bottom: none;
          }
        }
      }
      .fas,
      .far {
        margin-left: 5px;
        font-size: 0.8rem;
        color: rgba($secondary-color, 1);
        cursor: pointer;
      }
    }

    .documents-grid-name {
      font-size: 14px;
      color: $black;
      margin-bottom: 5px;
      text-align: left;
      font-family: "ralewayregular";
      font-weight: 600;
      word-break: break-all;
      min-height: 40px;
      height: 75px;
    }
    .documents-grid-date {
      font-size: 12px;
      color: rgba($black, 0.4);
      margin-bottom: 5px;
      text-align: left;
      font-family: "copperplate-bold";
    }

    .fa-download {
      @media (min-width: 960px) {
        display: none;
      }
    }
    &:hover {
      @media (min-width: 960px) {
        .fa-download {
          display: inline-block;
        }
      }
    }
    .icon-visibe {
      color: #0f76c2 !important;
      display: inline-block;
    }
  }

  .documents-list-head {
    color: $black;
    padding: 20px;
  }
  .documents-list-data {
    border-top: rgba($black, 0.05) 1px solid;
    padding: 20px;
    &:nth-child(odd) {
      background-color: none;
    }
    &:nth-child(even) {
      background-color: $white;
    }

    .documents-list-data-text {
      color: rgba($grey, 0.85);
      font-size: 1rem;
      display: flex;
      align-items: center;
      height: 100%;
    }
    .documents-list-data-actions {
      text-align: right;
      display: flex;
      align-items: center;
      height: 100%;
      justify-content: flex-end;
      .fas,
      .far {
        margin-right: 7px;
        font-size: 1rem;
        color: $secondary-color;
        cursor: pointer;
      }
    }
  }
}
.documents-dropmenu {
  display: inline-flex;
  min-width: 182px;
  position: relative;
  .documents-dropmenu-part {
    width: 100%;
    z-index: 2;
    left: 0 !important;
  }
  .MuiMenuItem-root {
    font-family: "ralewayregular" !important;
    font-size: 0.875rem;
    font-weight: 600;
  }
  .documents-dropmenu-button {
    background-color: $white;
    border: $border-color 1px solid;
    @include border-radius(0px);
    padding: 8px 12px;
    width: 100%;
    font-family: "ralewayregular";
    text-transform: unset;
    b {
      margin-left: 10px;
    }
    i {
      color: rgba($yellowGrey, 0.4);
      font-size: 0.7rem;
      margin-left: 20px;
    }
    .category-spinner {
      padding-left: 5px;
    }
  }
}

.documents-list-data-name {
  color: $black;
  font-size: 1rem;
  display: flex;
  align-items: center;
  padding: 0 10px;
  font-family: "ralewayregular";
  font-weight: 600;
  overflow-wrap: anywhere;
  @media (max-width: 600px) {
    padding: 0;
  }
  .documents-grid-img {
    font-size: 2rem;
    margin-bottom: 0;
    margin-right: 10px;
    font-weight: normal;
    @media (max-width: 960px) {
      margin-right: 18px;
    }
    @media (max-width: 600px) {
      display: none;
    }
  }
}
.header-filter-box {
  background-color: $white;
  @include box-shadow(0px, 2px, 4px, rgba($black, 0.1));
  padding: 10px 25px;
  @include border-radius(16px);
  position: relative;
  top: -48px;
  width: 100% !important;
  margin: 0 auto !important;
}

.error-wrapper {
  display: flex !important;
  color: $danger-color;
  flex-direction: row;
  align-items: center;
  .error-icon {
    color: $danger-color;
    font-size: 10px;
    margin-bottom: 2px;
    width: auto;
    margin-bottom: 4px;
    margin-right: 3px;
  }
  .error-text {
    font-size: 12px;
  }
}

#fileUpload {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
}

.action-button {
  display: flex;
  justify-content: flex-end;
  .document-loader {
    margin-top: 8px;
  }
  .document-modal-spinner {
    margin-top: 8px;
    position: absolute;
    right: 20px;
  }
}
.relative-wrapper {
  position: relative;
}

.loader-wrapper {
  position: absolute;
  width: 100%;
  background: rgba(255, 255, 255, 0.9);
  z-index: 3;
  height: 100%;
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  place-content: center;
  align-items: center;
  &.list-spinner {
    background: transparent;
    min-height: 300px;
  }
  .document-loader {
    height: 50px;
    width: 50px;
    margin: 0 auto;
    margin-top: 8px;
  }
}

.doc-desc {
  width: 80%;
  margin: auto;
}

.edit-dropzone {
  min-height: 192px;
}

.download-icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  .document-loader
  {
      width: 24px;
    > span {
      padding-left: 5px !important;
      text-align: center;
      display: inline-block;
      vertical-align: middle;
    }
  }
}

.document-loader-grid {
  position: absolute;
  top: 5px;
  left: 10px;
}

.document-detail-wrapper {
  border: 1px solid #ececed;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 5px;
  border-radius: 10px;
  .details {
    padding: 20px;
    text-align: left;
    .detail-label {
      font-weight: bold;
    }
    .eye-list-icon,
    .pen-list-icon,
    .trash-list-icon {
      &:hover {
        background-color: $primary-color;
      }
    }
  }
}

.btn-popup-action {
  position: relative;
  display: inline-block;
  .popup-alignment {
    right: 100%;
    position: relative;
    top: 20px;
    margin-right: -15px;
    li {
      min-height: auto;
    }
  }
}
