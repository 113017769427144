@import "../../scss/variables-mixin.scss";

// rebates
.rebates {
  margin: 0 auto;
}

// rebates header
.rebates-header {
  margin: 0 auto;
}

// rebates filter
.rebates-filter {
  margin: 0 auto;
  padding: 20px 0 40px 0;
  @media (max-width: 1280px) {
    padding: 0px 0 20px 0;
  }
  .header-filter-box-left {
    min-width: 200px;
  }
  .br-dropdown-menu {
    min-width: 200px;
    > div {
      width: auto;
      min-width: 100%;
    }
  }
  @media (max-width: 1280px) {
    .br-dropdown-menu {
      min-width: 100%;
      text-align: center;
    }
    .MuiGrid-container {
      flex-direction: column-reverse;
    }
    .MuiGrid-item {
      width: 100%;
    }
    .header-filter-box-search-add {
      flex-wrap: wrap;
      .header-filter-box-add {
        flex-grow: 1;
        .MuiButton-root {
          min-width: 100%;
        }
      }
      .header-filter-box-search {
        order: 1;
        flex: 1 0 100%;
        padding-top: 20px;
      }
    }
  }
}
.static-table-header {
  width: 100%;
  position: relative;
  z-index: 1;
  .tr {
    padding: 15px 0 !important;
    border-bottom: none;
  }
}

// rebates BR admin
.rebates-br-admin {
  margin: 0 auto;
  padding: 0 30px;
  .th {
    padding: 5px 12px;
  }
  .tr {
    padding: 15px 0px;

    .td {
      padding: 5px 10px;
    }
  }

  .grid-tr-detail-box {
    padding: 10px 20px;
    border-top: rgba($grey, 0.2) 1px solid;
    width: 100%;
    margin-top: 10px;
    position: relative;
    left: 0;
    display: none;
    .overflow-table-records {
      height: 30vh;
      overflow: auto;
      .grid-tr-detail-box-td {
        padding: 0 15px !important;
      }
    }
    .grid-tr-detail-box-head,
    .grid-tr-detail-box-data {
      border-bottom: rgba($grey, 0.2) 1px solid;
      padding: 12px 0px;
    }
    .grid-tr-detail-box-head {
      font-family: "ralewayregular";
      font-size: 0.813rem;
      color: $not-found-h;
    }
    .grid-tr-detail-box-data {
      &:last-child {
        border-bottom: none;
      }
      .grid-tr-detail-box-amount {
        font-family: "copperplate-bold";
        font-size: 0.875rem;
        color: $primary-color;
      }
      .grid-tr-detail-box-date {
        font-family: "copperplate-bold";
        font-size: 0.75rem;
        color: $grey;
      }
      .grid-tr-detail-box-pay-mode {
        font-family: "ralewayregular";
        font-size: 0.875rem;
        color: rgba($grey, 0.85);
      }
    }
  }
  .grid-tr-detail-box-td {
    padding: 5px 12px !important;
  }
  .rebates-member {
    font-size: 1rem;
    display: flex;
    align-items: center;
    font-family: "ralewayregular";
    font-size: 0.875rem;
    cursor: pointer;
    .fa-chevron-circle-down {
      color: #01518c;
    }
    i {
      color: $text-gray-40;
      font-size: 14px;
      margin-right: 15px;
    }
    span {
      line-height: 17px;
      color: rgba(85, 96, 106, 0.85);
    }
  }

  .member-name,
  .member-mail-id {
    padding: 1px 0;
  }
  .rebates-amount {
    font-size: 0.875rem;
    font-family: "copperplate-bold";
    color: $primary-color;
  }
  .rebates-deposited {
    font-family: "ralewayregular";
    font-size: 0.875rem;
    color: rgba($grey, 0.85);
    padding: 2px 0;
  }
  .rebates-date {
    font-family: "copperplate-bold";
    font-size: 0.75rem;
    color: $secondary-color;
    padding: 2px 0;
  }

  @media (max-width: 1280px) {
    padding: 0 10px;
  }
}

.notes-notFound {
  color: rgba(85, 96, 106, 0.66);
}

.member-rebates-table {
  margin: 20px auto;
  .member-rebates-table-amount {
    font-family: "copperplate-bold" !important;
    font-size: 0.875rem !important;
    color: $primary-color;
  }
  .member-rebates-table-date {
    font-family: "copperplate-bold" !important;
    font-size: 0.75rem !important;
    color: $grey;
  }
  .member-rebates-table-pay-mode {
    font-family: "ralewayregular" !important;
    font-size: 0.875rem !important;
    color: rgba($grey, 0.85);
  }
  .th {
    padding: 5px 20px;
  }
  .tr {
    padding: 17px 0px;
    .td {
      padding: 5px 20px;
    }
  }
  h3 {
    padding: 20px 10px;
  }
}
.header-filter-box-add {
  position: relative;
  align-items: center;
}
.rebates-menu {
  position: absolute;
  display: none;
  background-color: $white;
  top: 100%;
  min-width: 100%;
  z-index: 1;
  box-shadow: rgba($black, 0.2) -5px 5px 5px;
  white-space: nowrap;
  left: 50%;
  transform: translateX(-50%);
  ul {
    list-style: none;
    padding: 0 25px;
    li {
      padding: 10px 0;
      border-bottom: rgba($grey, 0.1) 1px solid;
      color: $yellowGrey;
      cursor: pointer;
      &:hover {
        color: $grey;
      }
      &:last-child {
        border-bottom: none;
      }
    }
  }
}
.button-menu-added {
  position: relative;
  .rebates-menu {
    display: inline-block;
  }
}
.grid-tr-detail-box {
  &.grid-tr-detail-box-close {
    display: block;
  }
}

.mode-tab {
  position: relative;
  .MuiTabs-indicator {
    display: none;
  }
  .MuiTabs-flexContainer {
    display: inline-flex;
    border-bottom: none;
    .MuiTab-root {
      border: rgba($grey, 0.2) 1px solid;
      min-width: auto;
      padding: 10px 20px;
      font-size: 0.875rem;
      font-family: "ralewayregular";
      text-transform: unset;
      &:first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        border-right: none;
      }

      &:last-child {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        border-left: none;
      }
    }
    .Mui-selected {
      background-color: $primary-color;
      border: $primary-color 1px solid;
      color: white;
      .MuiTab-wrapper {
        color: white;
      }
    }
  }
  .mode-rebate-total {
    display: inline-flex;
    align-items: center;
    // position: absolute;
    // right: 0;
    // top: 50%;
    // transform: translateY(-50%);
    .mode-rebate-total-text {

      padding-right: 15px;
      .mode-rebate-total-amount {
        font-size: 1.25rem;
        font-family: "copperplate-bold";
        color: $black;
        display: block;
        padding: 2px 0;
      }
      .mode-rebate-total-info {
        font-size: 0.75rem;
        font-family: "ralewayregular";
        color: $not-found-p;
        display: block;
        padding: 2px;
      }
    }
    .mode-rebate-total-icon {
      color: $success-dark-color;
      font-size: 2.25rem;
    }
  }
  .MuiTabs-fixed {
    position: relative;
  }
  .MuiBox-root {
    padding: 30px 0;
  }
  .electronic-tab-grid {
    margin-bottom: 20px;
  }
}

.check-tab {
  .deliver-notification {
    color: $primary-color;
    font-family: "ralewaybold";
    font-size: 1rem;
    padding: 10px 0;
    .fa-hand-point-right {
      margin-right: 5px;
    }
  }
  .check-deliver-box {
    display: flex;
    background-color: $community-light-grey;
    border: $circle-border 1px dashed;
    @include border-radius(5px);
    margin-left: 0px;
    margin-right: 0px;
    .Mui-checked {
      color: $primary-color;
    }
  }
  .highlight {
    background-color: $circle-border;
  }
  .MuiIconButton-root {
    background-color: transparent;
  }
  .MuiFormControlLabel-label {
    font-family: "ralewayregular";
    font-size: 0.875rem;
    color: $grey;
  }
}
.remind-rebates-icon {
  background-color: $success-color;
  color: $white;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  font-size: 0.75rem;
  @include border-radius(50%);
  cursor: pointer;
}
.xs-right-align-td {
  justify-content: right;
}
.last-head-right-td {
  text-align: right;
}
.rebates-product-data {
  color: $grey;
  font-size: 0.875rem;
  font-family: "ralewaybold";
}
.info-rebates-notes {
  background-color: $primary-color;
  color: $white;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  font-size: 0.75rem;
  @include border-radius(50%);
  cursor: pointer;
}
.edit-rebates-notes {
  background-color: $primary-color;
  color: $white;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  font-size: 0.75rem;
  @include border-radius(50%);
  cursor: pointer;
  margin-left: 5px;
  &:hover {
    background-color: $primary-hover-color;
  }
}
.rebates-number-data {
  font-size: 0.875rem;
  font-family: "copperplateregular";
  color: rgba($grey, 0.85);
}
.rebates-overview-box {
  display: flex;
  background-color: $white;
  padding: 10px;
  width: 350px;
  margin-bottom: 30px;
  @include box-shadow(0px, 2px, 5px, rgba($black, 0.05));
  .rebates-overview-box-icon {
    display: flex;
    color: $circle-border;
    font-size: 2.25rem;
    padding: 10px;
  }
  .rebates-overview-content {
    flex: 1 1 auto;
    width: 1%;
    padding: 10px 20px;
    .rebates-overview-money {
      font-family: "copperplate-bold";
      font-size: 1.25rem;
      margin-bottom: 7px;
    }
    .rebates-overview-money-text {
      font-family: "ralewayregular";
      font-size: 0.875rem;
      color: $not-found-p;
    }
  }
}
.rebates-overview-total {
  border-left: #d3e1f2 5px solid;
  .rebates-overview-box-icon {
    color: $circle-border;
  }
  .rebates-overview-money {
    color: $black;
  }
}
.rebates-overview-claimed {
  border-left: $br-green 5px solid;
  .rebates-overview-box-icon,
  .rebates-overview-money {
    color: $br-green;
  }
}
.rebates-overview-unclaimed {
  border-left: $br-red 5px solid;
  .rebates-overview-box-icon,
  .rebates-overview-money {
    color: $br-red;
  }
}
.rebates-overview-pending {
  border-left: $br-yellow 5px solid;
  .rebates-overview-box-icon,
  .rebates-overview-money {
    color: $br-yellow;
  }
}
.rebates-progress-legend-wrapper {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  text-align: center;
  padding: 5px 20px;
}
.rebates-total {
  font-size: 1.25rem;
  font-family: "copperplate-bold";
  padding: 5px 0;
}
.rebates-legend {
  color: $not-found-p;
  font-size: 0.875rem;
  padding: 5px 0;
}

.overview-chart-pie-chart-wrapper {
  display: flex;
  justify-items: center;
  width: 100%;
  height: 80%;
  min-height: 230px;
  position: relative;
}

.overview-chart-label {
  display: flex;
  align-items: center;
  justify-content: center;
  .overview-chart-label-content {
    display: flex;
    align-items: center;
    padding: 5px 10px;
  }
  .overview-chart-label-indicator {
    width: 20px;
    height: 4px;
    background-color: $circle-border;
    display: inline-block;
  }
  .overview-chart-label-text {
    display: inline-block;
    color: $br-gray;
    padding-left: 5px;
    font-size: 0.875rem;
    font-family: "ralewayregular";
  }
  .overview-chart-label-indicator-grey {
    background-color: $circle-border;
  }
  .overview-chart-label-indicator-green {
    background-color: $br-green;
  }
  .overview-chart-label-indicator-yellow {
    background-color: $br-yellow;
  }
  .overview-chart-label-indicator-red {
    background-color: $br-red;
  }
}
.upload-rebateform {
  .upload-rebateform-grid {
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .upload-rebateform-heading {
    font-size: 1rem;
    font-family: "ralewaybold";
    color: $grey;
    margin-bottom: 20px;
  }
  .upload-rebateform-subheading {
    font-size: 0.875rem;
    font-family: "ralewayregular";
    color: $grey;
    margin: 14px 0;
  }
  .file-filed-document {
    left: 0;
    width: 100%;
  }
  .br-checkbox:focus-within {
    background-color: $checkbox-background;
  }
  .edit-doc-section {
    margin: 0px auto;
    font-family: "ralewayregular";
    width: 100%;
    .edit-doc-section-box {
      background-color: rgba($primary-color, 0.06);
      @include border-radius(10px);
      padding: 20px;
      width: 100%;
      margin: 0 auto;
      text-align: center;
      .edit-doc-section-name {
        font-size: 1rem;
        color: $file-name-color;
        width: 100%;
        white-space: normal;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0 10px;
      }
      .edit-doc-section-size {
        font-size: 0.7rem;
        color: $secondary-color;
        font-family: "copperplate-bold";
        padding: 10px 0;
      }
      .edit-doc-section-buttons {
        display: flex;
        margin: 5px auto;
        justify-content: center;
        .remove-button {
          background-color: none;
          color: $danger-color;
          text-transform: unset;
          margin: 5px;
        }
        .replace-button {
          background-color: $logo-color;
          color: $white;
          text-transform: unset;
          font-family: "copperplate-bold";
          padding: 10px 15px 5px 15px;
          margin: 5px;
          cursor: pointer;
          transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
            box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
          @include border-radius(5px);
          &:hover {
            background-color: $logo-color-dark;
          }
        }
      }
    }
    .edit-doc-section-input {
      padding: 10px 0;
      margin: 10px auto;
      width: 80%;
    }
    .edit-doc-section-text-area {
      width: 100%;
      .doc-text-area {
        height: 10vh;
      }
    }
    .edit-doc-section-select {
      padding: 10px 0;
      margin: 10px auto;
      width: 80%;
    }
  }
}

.export-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top:10px;
  .date-range {
    margin: 15px auto;
    .rdrCalendarWrapper {
      border-radius: 10px;
    }
  }
}
.edit-member-rebates-doc-title {
  font-family: "copperplateregular";
  font-size: 1.2rem;
  color: $primary-color;
  padding-bottom: 15px;
  span {
    font-family: "copperplate-bold";
  }
}
.edit-rebate-documents {
  display: flex;
  align-items: stretch;
  justify-content: center;
  .edit-rebate-documents-box {
    width: 33%;
    flex-grow: 1;
    padding: 10px;
    .file-filed-document {
      width: 100%;
      left: 0;
    }
  }
  .edit-rebate-documents-icon {
    text-align: center;
  }
  .edit-rebate-documents-name {
    font-size: 1rem;
    color: $file-name-color;
    font-family: "ralewaybold";
  }
  .edit-rebate-documents-date {
    font-size: 0.75rem;
    font-family: "copperplateregular";
    color: $secondary-color;
    margin-bottom: 10px !important;
    margin-top: 5px !important;
  }
  .edit-doc-section-box {
    width: 100% !important;
  }
}

.tab-button {
  display: none;
}
@media (max-width: 1024px) {
  .tab-ul {
    position: relative;
    .tab-button {
      display: block;
      position: absolute;
      width: 100%;
      height: 90%;
      z-index: 3;
      background: transparent;
      border: rgba($grey, 0.2) 1px solid;
      @include border-radius(14px);
      text-align: left;
      padding-left: 15px;
      font-size: 1rem;
      font-family: "ralewayregular";
      .fa-caret-down {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .MuiTabs-indicator {
      display: none;
    }
    .MuiTabs-root,
    .MuiTabs-fixed {
      overflow: visible !important;
      z-index: 2;
    }
    .MuiTab-root {
      min-width: 100%;
    }
    .MuiTabs-flexContainer {
      position: absolute;
      display: none;
      top: 100%;
      width: 100%;
      background-color: $white;
      transition: 0.3s;
      @include box-shadow(-5px, 5px, 10px, rgba(0, 0, 0, 0.2));
      @include border-radius(6px);
      .MuiButtonBase-root {
        width: 100%;
        display: block;
        height: 0;
      }
    }
  }
  .tab-ul-open {
    .MuiTabs-flexContainer {
      display: block;
      pointer-events: auto;
      opacity: 1;
      height: auto;
    }
  }
}
@media (max-width: 1280px) {
  .rebates-overview-total,
  .rebates-overview-claimed,
  .rebates-overview-unclaimed,
  .rebates-overview-pending {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .page-search-box {
    width: 100% !important;
  }
  .header-filter-box-search {
    padding-top: 0px !important;
  }
  .header-filter-box-add {
    padding-left: 0px !important;
  }
}

.radio-button-wrapper {
  margin-bottom: 20px;
  .MuiGrid-root {
    padding: 0 10px;
  }
}
.rebates-file-upload {
  min-height: 226px;
  display: flex;
  .file-filed-document {
    margin-bottom: 0px !important;
  }
}
.member-rebates-file-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  .edit-doc-section {
    flex-basis: 31%;
  }
}
.member-rebates-update-wrapper {
  display: flex;
}
.member-admin-rebates {
  .br-dropdown-menu {
    min-width: 170px;
    .MuiButton-root {
      min-width: 145px;
    }
  }
}
.active-option {
  font-family: "copperplate-bold";
}

.rebates-dollar-wrapper {
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  @media (max-width: 900px) {
    justify-content: center;
  }
  .mode-rebate-total {
    display: inline-flex;
    align-items: center;
    margin-left: 20px;
    margin-bottom: 20px;
    position: relative;
    padding-right: 16px;
    &::after {
      border-right: 1px solid rgba($black, 0.1);
      content: "";
      height: 65%;
      position: absolute;
      right: 0;
      @media (max-width: 480px) {
        display: none;
      }
    }
    &:last-child {
      &::after {
        border-right: none;
      }
    }

    .mode-rebate-total-text {
      padding: 0 0 0 20px;
      // margin-right: 20px;
      .mode-rebate-total-amount {
        font-size: 1.25rem;
        font-family: "copperplate-bold";
        color: $black;
        display: block;
        padding: 2px 0;
      }
      .mode-rebate-total-info {
        font-size: 0.75rem;
        font-family: "ralewayregular";
        color: $not-found-p;
        display: block;
        padding: 2px;
      }
    }
    .mode-rebate-pending-icon {
      color: $orange !important;
    }
    .mode-rebate-total-icon {
      color: $success-dark-color;
      font-size: 2.25rem;
      .available-rebate {
        color: $danger-color;
      }
    }
  }
}
.rebate-completed {
  color: $success-color;
}
.rebate-pending {
  color: $orange;
}

.rebate-cancelled {
  color: $secondary-color;
}

.rebate-failed {
  color: $danger-color;
}

.rebates-action-header {
  margin: 10px 0;
  strong {
    font-weight: bold;
    color: $logo-color;
  }
}
.rebates-action-section {
  margin-top: 40px;
  div:first-child {
    font-weight: bold;
    margin-bottom: 10px;
  }
  .custom-radio-group {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    .MuiRadio-colorSecondary {
      &.Mui-checked {
        color: $logo-color;
      }
    }

    .MuiIconButton-root {
      background: transparent;
    }

    .Mui-checked:hover {
      background-color: rgba($logo-color, 0.04) !important;
    }

    .MuiIconButton-colorSecondary:hover {
      background-color: rgba($logo-color, 0.04);
    }
    .MuiFormControlLabel-root {
      margin-bottom: 5px;
    }
    .MuiFormControlLabel-label {
      color: $not-found-h;
      font-family: "ralewayregular";
    }

    .MuiRadio-root {
      color: $not-found-h;
    }
  }
}
.rebate-icon {
  width: 50px;
}

.add-new-account {
  font-size: 0.75rem;
  font-family: "ralewayregular";
  color: $not-found-p;
}

.rebates-overview-wrapper{
  padding:1rem 0;
}