@import "../../../scss/variables-mixin.scss";
.MuiTabs-flexContainer {
  border-bottom: rgba(0, 0, 0, 0.1) 1px solid;
}

.assign-box {
  background-color: rgba($primary-color, 0.06);
  border: $secondary-color 2px dashed;
  @include border-radius(2px);
  width: 100%;
  height: 150px;
  font-size: 16px;
  font-weight: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  .assign-icon {
    font-size: 18px;
    color: $secondary-color;
    margin-right: 10px;
  }
  label {
    text-transform: unset;
  }
}

.locations-tab {
  .th-title {
    font-weight: bold;
    a {
      text-decoration: underline;
      color: inherit;
      &:hover {
        color: rgba($black, 0.7);
      }
    }
  }
  tr {
    border-bottom: rgba($yellowGrey, 0.15) 1px solid;
  }
}
.vendor-assign-content {
  tr {
    border-bottom: rgba($yellowGrey, 0.15) 1px solid;
  }
}
.uage-tab {
  p {
    padding: 20px;
    font-size: 20px;
    text-align: center;
    width: 70%;
    margin: 0 auto;
    line-height: 140%;
  }
}

$min-width: 1280px;
$max-width: 1440px;

@mixin screen-range {
  @media screen and (min-width: #{$min-width}) and (max-width: #{$max-width}) {
    @content;
  }
}

.alignResponsive {
  @include screen-range {
    align-items: flex-start !important;
  }
}