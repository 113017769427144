@import "../../../../../scss/variables-mixin.scss";

.chart-wrapper {
  display: flex;
  width: 100%;
  .pie-header {
    position: relative;
    width: 100%;
    top: 0px;
    text-align: left;
    .pie-title {
      font-size: 23px;
      padding: 5px 0 5px 0;
    }
    .pie-sub-title {
      color: rgb(130, 129, 129);
      font-size: 14px;
      padding: 5px 0 5px 0;
      .pie-sub-text {
        font-size: 12px;
        padding: 5px 0;
      }
    }
  }
  > div {
    position: relative;
    display: flex;
    align-items: center;
    flex-grow: 1;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 5px;
    border-radius: 10px;
    padding: 20px;
    margin: 0px 10px;
    width: 100%;
    flex-direction: column;
    @media (max-width: 1280px) {
      margin: 10px 0px;
    }
    .recharts-responsive-container {
      padding-top: 0px !important;
      margin-top: 10px;
    }
  }
  .CircularProgressbar {
    margin-top: 130px;
    .CircularProgressbar-text {
      font-size: 12px;
      dominant-baseline: hanging;
    }
  }
  .CircularProgressbar-text-no-data {
    margin-top: 60px;
  }
  .drop-graph-avgscore-stable {
    display: block;
    color: $inside-terms-color;
  }
}
.drop-graph-avgscore-unstable {
  display: block;
}
.terms-count-wrapper {
  position: absolute;
  left: 49%;
  font-size: 18px;
  font-family: "copperplateregular";
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
  width: 100%;
  text-align: center;
  span {
    display: block;
  }
  .within-terms-count {
    margin: 10px;
    color: $inside-terms-color;
  }
  .outside-terms-count {
    margin: 10px;
    color: $outside-terms-color;
  }
}

.terms-count-wrapper-member {
  position: absolute;
  left: 49%;
  font-size: 18px;
  font-family: "copperplateregular";
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
  width: 100%;
  text-align: center;
  span {
    display: block;
  }
  .within-terms-count {
    margin: 10px;
    color: $inside-terms-color;
  }
  .outside-terms-count {
    margin: 10px;
    color: $outside-terms-color;
  }
}

.graph-loader {
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 1280px) {
  .chart-wrapper {
    flex-direction: column;
    .dashboard-pie-chart,
    .progress-bar {
      width: 100%;
    }
  }
}
.dashboard-pie-chart-box {
  position: relative;
  > svg {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    .dashboard-pie-chart-box-chart {
      transform-origin: center;
    }
  }
}
.recharts-legend-item {
  padding-left: 15px;
  position: relative;
  padding-bottom: 3px;
  .recharts-surface {
    position: absolute;
    left: 0;
    top: 2px;
  }
}
.dashboard-progress-bar {
  margin: auto;
  width: 100%;
}
.circular-bar-count {
  position: absolute;
  font-family: "copperplateregular";
  color: $drop-size-color;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  > span {
    margin: 10px;
  }
}
.progress-legend-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.icon-claimed-rebates {
  width: 10px;
  height: 8px;
  background-color: $br-green;
  display: inline-block;
  vertical-align: middle;
}
.text-claimed-rebates {
  padding-left: 4px;
  font-size: 0.75rem;
  color: $br-green;
  display: inline-block;
  vertical-align: middle;
}
.icon-unclaimed-rebates {
  width: 10px;
  height: 8px;
  background-color: $br-red;
  display: inline-block;
  vertical-align: middle;
}
.text-unclaimed-rebates {
  padding-left: 4px;
  font-size: 0.75rem;
  color: $br-red;
  display: inline-block;
  vertical-align: middle;
}
.terms-no-data {
  .recharts-surface {
    width: 100%;
    height: 100%;
  }
}
.dashboard-pie-chart {
  height: 200px;
  .recharts-wrapper {
    width: 100% !important;
    height: 100% !important;
    .recharts-legend-wrapper {
      right: -15px !important;
      top: 26px !important;
      width: auto !important;
    }
    .recharts-tooltip-wrapper {
      z-index: 2;
    }
  }
  .recharts-responsive-container {
    height: 100%;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
  }
  .custom-tooltip {
    padding: 10px;
    font-weight: 400;
    border: 0.5px solid gray;
    background: rgba(255, 255, 255, 0.9);
    z-index: 3;
    border-radius: 4px;
    .intro {
      font-weight: bold;
      border-bottom: 0.5px solid black !important;
      padding: 5px;
    }
    .tooltip-content {
      font-size: 14px;
    }
  }
}
.terms-graph-nodata {
  position: absolute;
  left: 50%;
  font-size: 12px;
  top: 50%;
  transform: translate(-50%, -50%);
}
.drop-graph-nodata {
  position: absolute;
  left: 50%;
  font-size: 12px;
  top: 50%;
  transform: translate(-50%, -50%);
}
.kpi-details-nodata {
  min-height: 460px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dashboard-progress-bar {
  display: flex;
  align-items: center;
  text-align: center;
  svg {
    width: 100% !important;
    height: auto !important;
    transform: scale(1.15);
  }
}
.progress-details {
  position: relative;
  width: 100%;
  text-align: center;
  bottom: 5px;
  > div {
    padding: 5px;
  }
  .extra-high-range {
    font-family: "copperplateregular";
    color: $inside-terms-color;
    font-weight: bold;
  }
  .below-avg-score {
    font-family: "copperplateregular";
    color: $drop-size-color;
    font-weight: bold;
  }
  .within-avg-score {
    font-family: "copperplateregular";
    color: $success-dark-color;
    font-weight: bold;
  }
  .above-avg-score {
    font-family: "copperplateregular";
    color: $inside-terms-color;
    font-weight: bold;
  }
}
.progress-bar {
  flex-direction: column;
}
