// variables
$white: #fff;
$black: #000;
// $primary-color: #0f76c2;
$primary-color: #01518c;
$secondary-color: #95a9ba;
$border-color: #ececed;
$danger-color: #eb5757;
$yellowGrey: #51513d;
$grey: #55606a;
$bgColor: #f7f8fc;
$warning-color: #f2c94c;
$orange: #f2994a;
$purple: #bb6bd9;
$success-color: #4aaf05;
$not-found-p: #798897;
$not-found-h: #414a52;
$row-select-bg: #56ccf2;
$email-color: #859bae;
$success-dark-color: #27ae60;
$alert-grey: #edf2f7;
$tooltip-color: #798897;
$success-dark-hover-color: #198a48;
$primary-hover-color: #0b5f9b;
$danger-hover-color: #d64545;
$logo-color: #01518c;
$change-password-bgcolor: #e5ecf6;
$warning-hover-color: #d1a82f;
$active-community-channel: #e9f7ef;
$drop-size-color: #ff9900;
$inside-terms-color: #0f76c2;
$outside-terms-color: #e15759;
$text-gray-10: #fafafc;
$text-gray-20: #e6e8ef;
$text-gray-30: #c8d4e3;
$text-gray-40: #c5d8e8;
$text-gray-50: #c5d2dd;
$text-gray-60: #909fac;
$text-gray-70: #59626a;
$text-gray-80: #59626a;
$text-gray-90: #444c52;
$text-gray-100: #2f3438;

$xls-bg-color: #9b51e0;
$upload-color: #80b4fb;
$file-name-color: #2b3238;

$logo-color-dark: #004272;
$side-menu-active-color: #003966;

$circle-border: #d3e1f2;
$community-light-grey: #f5f7fd;

$community-button-grey: #a9bed1;

$text-auto-fill-color: #f0f0f0;
$text-auto-fill-border: #f0f0f0;

$br-green: #27AE60;
$br-red: #e15759;
$br-yellow: #FF9900;
$br-gray: #9c9c9c;
$checkbox-background: #e0e8ee;

// mixin
@mixin border-radius($radius) {
  border-radius: $radius !important;
  -webkit-border-radius: $radius !important;
  -o-border-radius: $radius !important;
  -moz-border-radius: $radius !important;
}
@mixin box-shadow($x-axis, $y-axis, $blur, $color) {
  box-shadow: $x-axis $y-axis $blur $color;
  -webkit-box-shadow: $x-axis $y-axis $blur $color;
  -o-box-shadow: $x-axis $y-axis $blur $color;
  -moz-box-shadow: $x-axis $y-axis $blur $color;
}
