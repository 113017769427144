.recharts-responsive-container {
  height: 100%;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.mini-pie-chart-box {
  position: relative;
  > svg {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    .mini-pie-chart-box-chart {
      transform-origin: center;
    }
  }
}