@import 'variables-mixin.scss';
@import url('../css/fonts.css');

.container {
  padding: 20px 30px;
  @media (max-width: 600px) {
    padding: 20px;
  }
  .page-title {
    font-size: 20px;
    font-family: 'ralewaybold';
    color: $primary-color;
  }
}

// button
.MuiButton-outlinedSecondary {
  color: $danger-color !important;
  border: 1px solid rgba($danger-color, 0.5) !important;
  &:hover {
    color: white !important;
  }
}

// Login Page
.login-form {
  button {
    padding: 15px 15px;
    color: $white;
    text-align: center;
    border: none;
    font-size: 16px;
    border-radius: 5px;
    font-family: 'robotobold';
    margin-top: 30px;
    &.btn-sign-in {
      width: 100%;
      background-color: $primary-color;
    }
    &.btn-sign-in-disable {
      width: 100%;
      color: $white;
      cursor: not-allowed;
    }
    &.btn-social {
      background-color: $white;
      text-transform: none;
      font-size: 14px;
      font-weight: normal;
      color: $secondary-color;
      width: 48%;
      img {
        margin-right: 10px;
        width: 15px;
      }
    }
  }
}

// Members Page
.page-head {
  display: inline-block;
  padding-left: 22px;
  margin: 5px 0;
  .grid-view {
    color: $primary-color;
  }
  .list-view {
    color: $secondary-color;
  }
}

.view-icon {
  margin: 5px 0;
  color: $secondary-color;
  i {
    margin-left: 20px;
    cursor: pointer;
  }
  .active {
    color: $primary-color;
    cursor: pointer;
  }
}

.page-search-box {
  height: 48px;
  @include border-radius(54px);
  border: $border-color 1px solid;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 15px;
  background: $white;
  .fa-search {
    color: rgba($black, 0.4);
    padding-right: 10px;
    padding-left: 5px;
  }
  .MuiInputBase-root {
    width: 100%;
  }
  .MuiInputBase-input {
    padding: 10px;
  }
}

.btn-add-controls {
  padding: 8px 20px !important;
  background-color: $success-dark-color !important;
  @include border-radius(50px);
  text-transform: unset !important;
  font-size: 0.75rem !important;
  color: $white !important;
  font-family: 'copperplate-bold' !important;
  align-items: center;
  span {
    padding: 0 3px;
  }
}

.btn-disabled {
  padding: 8px 20px !important;
  background-color: rgba($grey, 0.3) !important;
  @include border-radius(50px);
  text-transform: unset !important;
  font-size: 0.875rem !important;
  color: $white !important;
  font-family: 'copperplate-bold' !important;
  span {
    display: inline-block;
    vertical-align: middle;
    padding: 0 2px;
  }
  &:hover {
    background-color: $success-dark-hover-color !important;
  }
  &.ml-10 {
    margin-left: 10px;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
}

.btn-add {
  padding: 8px 20px !important;
  background-color: $success-dark-color !important;
  @include border-radius(50px);
  text-transform: unset !important;
  font-size: 0.875rem !important;
  color: $white !important;
  font-family: 'copperplate-bold' !important;
  span {
    display: inline-block;
    vertical-align: middle;
    padding: 0 2px;
  }
  &:hover {
    background-color: $success-dark-hover-color !important;
  }
  &.ml-10 {
    margin-left: 10px;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
}

.btn-publish {
  padding: 5px 20px !important;
  background-color: $success-dark-color !important;
  @include border-radius(4px);
  text-transform: unset !important;
  font-size: 0.875rem !important;
  color: $white !important;
  font-family: 'copperplate-bold' !important;
  span {
    display: inline-block;
    vertical-align: middle;
    padding: 0 2px;
  }
  &:hover {
    background-color: $success-dark-hover-color !important;
  }
  &.ml-10 {
    margin-left: 10px;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
}

.btn-edit {
  padding: 5px 20px !important;
  height: 34px;
  background-color: transparent !important;
  @include border-radius(5px);
  text-transform: unset !important;
  font-size: 0.875rem !important;
  color: $grey !important;
  font-family: 'copperplate-bold' !important;
  border: $tooltip-color 1px solid !important;
  width: 100%;
  box-shadow: none !important;
  span {
    display: inline-block;
    vertical-align: middle;
    padding: 0 2px;
  }
  &:hover {
    background-color: $white !important;
  }
}

.btn-delete {
  padding: 5px 20px !important;
  background-color: transparent !important;
  @include border-radius(5px);
  text-transform: unset !important;
  font-size: 0.875rem !important;
  color: $secondary-color !important;
  font-family: 'copperplate-bold' !important;
  border: none !important;
  width: 100%;
  margin-top: 10px !important;
  box-shadow: none !important;
  span {
    display: inline-block;
    vertical-align: middle;
    padding: 0 2px;
  }
  &:hover {
    background-color: transparent !important;
    color: $grey !important;
  }
}

.btn-add-blue {
  padding: 7px 20px !important;
  background-color: $primary-color !important;
  @include border-radius(50px);
  text-transform: unset !important;
  font-size: 0.875rem !important;
  color: $white !important;
  font-family: 'copperplate-bold' !important;
  span {
    display: inline-block;
    vertical-align: middle;
    padding: 0 2px;
  }
  &:hover {
    background-color: $primary-hover-color !important;
  }
  &.ml-10 {
    margin-left: 10px;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
}

// details header styles
.details-header {
  width: 100%;
  min-height: 150px;
  position: relative;

  @media only screen and (max-width: 600px) {
    min-height: 230px;
  }

  .details-header-img {
    width: 100%;
    position: absolute;
    height: 100%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
    .details-header-backdrop {
      background: rgb(187, 119, 241);
      background: linear-gradient(
        50deg,
        rgba(187, 119, 241, 1) 0%,
        rgba(15, 118, 194, 1) 100%
      );
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0.85;
    }
    .details-header-content {
      position: absolute;
      top: 0;
      padding: 15px 30px;
      height: 100%;
      .details-header-back {
        display: inline-block;
        vertical-align: middle;
        padding-right: 10px;
        color: $white;
        cursor: pointer;
        i {
          font-size: 18px;
          padding-right: 10px;
        }
        span {
          font-size: 16px;
        }
      }
      .details-header-breadcrumb {
        display: inline-block;
        vertical-align: middle;
        font-size: 14px;
        margin-left: 30px;
        > div {
          display: inline-block;
          vertical-align: middle;
          padding-right: 12px;
          color: $white;
          a {
            color: inherit;
            text-decoration: underline;
            text-decoration-thickness: 0.01em;
            .fa-chevron-right {
              font-size: 10px;
            }
          }

          span {
            padding-left: 5px;
          }
        }

        div {
          &:last-child {
            a {
              text-decoration: none;
            }
          }
        }
      }

      .details-header-title {
        font-size: 1.5rem;
        color: $white;
        font-family: 'copperplate-bold';
        span {
          font-size: 1.5rem;
          padding-left: 15px;
          font-weight: 200;
          font-family: 'copperplate-bold';
          padding-left: 5px;
        }
      }

      .details-header-member-info {
        font-family: 'copperplate-light';
        font-size: 1.1rem;
        color: $white;
      }

      .details-header-subtitle {
        font-size: 1.5rem;
        font-weight: bold;
      }

      .details-header-edit {
        line-height: 35px;
        background-color: $white;
        @include border-radius(50px);
        color: $white;
        display: inline-block;
        vertical-align: middle;
        padding: 0 20px;
        cursor: pointer;
        i {
          font-size: 14px;
          color: $primary-color;
        }
        span {
          font-size: 1rem;
          padding-left: 10px;
          font-family: 'copperplate-bold';
          color: $primary-color;
        }
        &:hover {
          background-color: $primary-color;
          i {
            color: $white;
          }
          span {
            color: $white;
          }
        }
      }

      @media only screen and (max-width: 1252px) {
        .details-header-edit {
          padding: 0 12px;
          span {
            display: none;
          }
        }
      }
      .details-header-message {
        line-height: 35px;
        border: $white 1px solid;
        @include border-radius(50px);
        color: $white;
        display: inline-block;
        vertical-align: middle;
        padding: 0 20px;
        margin-left: 15px;
        cursor: pointer;
        i {
          font-size: 14px;
        }
        span {
          font-size: 1rem;
          padding-left: 10px;
          font-family: 'copperplate-bold';
        }
        &:hover {
          background-color: rgba($bgColor, 0.1);
        }
      }

      @media only screen and (max-width: 1252px) {
        .details-header-message {
          padding: 0 11px;
          margin-left: 4px;
          span {
            display: none;
          }
        }
      }

      .details-header-delete {
        width: 35px;
        height: 35px;
        @include border-radius(50%);
        background-color: $white;
        color: $danger-color;
        text-align: center;
        display: inline-block;
        vertical-align: middle;
        line-height: 35px;
        margin-left: 15px;
        cursor: pointer;
        &:hover {
          background-color: rgba($white, 0.9);
        }
      }

      @media only screen and (max-width: 1252px) {
        .details-header-delete {
          margin-left: 4px;
          padding: 0 11px;
        }
      }
    }
  }
}
.details-info-box {
  background-color: $white;
  @include box-shadow(0, 2px, 4px, rgba($black, 0.05));
  min-height: 140px;
  padding: 10px 30px;
  position: relative;

  .details-info-user {
    display: flex;
    @media (max-width: 600px) {
      flex-direction: column;
      align-items: center;
    }
  }
  .details-info-user-left {
    display: flex;
  }
  .details-info-user-right {
    flex: 1 1 auto;
    width: 1%;
    padding: 0 20px;
    @media (max-width: 600px) {
      width: auto;
      margin-top: -25px;
    }
  }
  .details-user-img {
    width: 105px;
    height: 105px;
    @include border-radius(50%);
    border: $white 4px solid;
    display: inline-flex;
    position: relative;
    background-color: $secondary-color;
    top: -45px;
    left: 50%;
    transform: translateX(-50%);
    overflow: hidden;
    img {
      object-fit: cover;
      width: 100%;
      height: auto;
      object-position: center;
      max-width: 100%;
    }
  }
  .details-user-name {
    font-size: 20px;
    color: $primary-color;
  }

  .details-user-content {
    padding: 10px 0;
    border-bottom: $border-color 1px solid;
    &:last-child {
      border-bottom: none;
    }
    .details-user-content-text {
      padding-right: 20px;
      display: flex;
      font-size: 12px;
      white-space: nowrap;
      align-items: center;
      @media (max-width: 600px) {
        justify-content: center;
      }
      i {
        font-size: 13px;
      }
      span {
        color: $secondary-color;
        padding: 0 8px;
        font-size: 0.875rem;
        font-family: 'copperplateregular';
      }
    }
  }
}

.details-business-info-box {
  display: inline-block;
  vertical-align: middle;
  padding: 0px 15px;
  border-right: $border-color 1px solid;
  text-align: center;
  max-width: 150px;
  &:last-child {
    border-right: none;
  }
  .details-business-info-icon {
    font-size: 36px;
    .details-blue-icon {
      color: $primary-color;
    }
    .details-orange-icon {
      color: $orange;
    }
    .details-purple-icon {
      color: $purple;
    }
    .details-green-icon {
      color: $success-color;
    }
  }
  .details-business-info-count {
    font-size: 1.25rem;
    color: $black;
    padding: 5px 0;
    white-space: nowrap;
    font-family: 'copperplate-bold';
  }
  .details-business-info-message {
    font-size: 0.75rem;
    color: $secondary-color;
    font-family: 'ralewayregular';
  }
}
.align-right {
  text-align: right;
}
.section-not-found {
  text-align: center;
  margin: 20px auto;
  width: 60%;
  .section-not-found-img {
    padding: 20px 0;
  }
  .in-progress-img {
    width: 92px;
    padding: 20px 0;
    margin: auto;
    img {
      width: 100%;
    }
  }
  h4 {
    font-size: 1.1rem;
    color: $not-found-h;
    font-family: 'ralewaybold';
  }
  p {
    font-size: 1rem;
    color: $not-found-p;
    margin: 10px auto;
    font-family: 'ralewayregular';
  }
}

////////////// Table Grid //////////////

.table-wrapper {
  .th {
    padding: 10px;
    color: $text-gray-60;
    font-size: 0.875rem;
    font-family: 'ralewayregular';
    @media (max-width: 1278px) {
      display: none;
    }
  }

  .tr {
    border-bottom: $text-gray-30 1px solid;
    padding: 0px;
    position: relative;
    @media (max-width: 1280px) {
      padding: 10px;
    }
    &.thead {
      padding: 4px 0px;
      background: none;
    }
    .td {
      padding: 10px;
      font-size: 0.875rem;
      border-bottom: none;
      font-family: 'ralewayregular';
      &:first-child {
        justify-content: left;
        @media (max-width: 1280px) {
          max-width: 75%;
          word-break: break-word;
        }
      }
      &.action-col {
        justify-content: flex-end;
        @media (max-width: 1279px) {
          position: absolute;
          padding-right: 15px;
        }
      }
      // &.xs-left-align {
      //   @media (max-width: 1280px) {
      //     padding-left: 53px;
      //     font-size: 14px;
      //     &:nth-child(1)
      //     {
      //       padding-left: 0px;
      //     }
      //   }
      //   @media (max-width: 1280px) {
      //     padding-left: 0px;
      //   }
      // }
      &.xs-primary:nth-child(2) {
        @media (max-width: 1280px) {
          color: $primary-color;
        }
      }
      @media (max-width: 1280px) {
        padding: 3px 0px;
      }
      &:hover {
        background: none;
      }
      .MuiButton-root {
        margin-left: 7px;
        @media (max-width: 1280px) {
          margin-left: 5px;
        }
        i {
          font-size: 11px;
        }
        &.btn-circle {
          min-width: auto;
          padding: 5px;
          height: 24px;
          width: 24px;
          @include border-radius(46px);
          box-shadow: none;
          text-align: center;
          &:hover {
            background-color: $primary-color;
          }
        }
        &.btn-green {
          min-width: auto;
          padding: 5px;
          height: 24px;
          width: 24px;
          color: $white;
          @include border-radius(46px);
          box-shadow: none;
          text-align: center;
          background-color: $success-dark-color;
          &:hover {
            background-color: $success-color;
          }
        }
        &.btn-red {
          min-width: auto;
          padding: 5px;
          height: 24px;
          width: 24px;
          color: $white;
          @include border-radius(46px);
          box-shadow: none;
          text-align: center;
          background-color: $danger-color;
          &:hover {
            background-color: $danger-hover-color;
          }
        }
        &.btn-circle-trash {
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: auto;
          padding: 5px;
          height: 24px;
          width: 24px;
          @include border-radius(46px);
          box-shadow: none;
          text-align: center;
          &:hover {
            background-color: $danger-color;
            color: white;
          }
          i {
            line-height: 10px;
          }
        }
        &.btn-circle-info {
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: auto;
          padding: 5px;
          height: 24px;
          width: 24px;
          @include border-radius(46px);
          box-shadow: none;
          text-align: center;
          color: $white !important;
          border-color: $secondary-color !important;
          background-color: $secondary-color;
          &:hover {
            background-color: $white !important;
            color: $secondary-color !important;
          }
          i {
            line-height: 10px;
          }
        }
        &.btn-circle-tick {
          min-width: auto;
          padding: 5px;
          height: 24px;
          width: 24px;
          @include border-radius(46px);
          box-shadow: none;
          text-align: center;
          border-color: $success-color !important;
          background-color: $success-color;
          color: $white !important;
        }

        &.btn-secondary {
          background-color: $secondary-color;
          color: $white;
        }
        &.btn-primary {
          background-color: $primary-color;
          color: $white;
        }
      }
    }
    &:nth-child(odd) {
      background: $bgColor;
      &.thead {
        background: none;
      }
    }
    &:nth-child(even) {
      background: $white;
      &.thead {
        background: none;
      }
    }
  }
  table {
    tbody {
      tr {
        td {
          padding: 10px !important;
          font-size: 14px;
          border-bottom: none;
          height: 60px;
          &:hover {
            background: none !important;
          }
        }
        &:nth-child(odd) {
          background: $white;
        }
        &:nth-child(even) {
          background: $bgColor;
        }
      }
    }

    thead {
      th {
        padding: 10px !important;
        height: 60px;
      }
    }
    .MuiIconButton-label {
      color: $secondary-color;
    }
    .Mui-selected {
      background: rgba($row-select-bg, 0.1) !important;

      td {
        color: $primary-color !important;
      }
      .Mui-checked {
        .MuiIconButton-label {
          color: $primary-color;
        }
      }
    }
  }
  .table-member-details {
    display: flex;
    align-items: center;
    font-family: 'ralewayregular';
    .member-img {
      img {
        width: 32px;
        height: 32px;
        @include border-radius(46px);
        object-fit: cover;
      }
    }
    .member-profile-details {
      padding-left: 20px;
      .member-name {
        font-size: 0.875rem;
        font-weight: bold;
        cursor: pointer;
        font-family: 'ralewayregular';
      }
      .member-mail-id {
        font-size: 12px;
        color: $email-color;
        cursor: pointer;
        font-weight: normal;
        font-family: 'copperplateregular' !important;
        word-break: break-all;
      }
    }
  }
}

.form-h4 {
  margin-top: 30px;
  margin-bottom: 15px;
  font-size: 14px;
}

.br-form-element {
  padding: 20px 0;
}

.select-box {
  width: 100%;
  .MuiFormLabel-root {
    font-family: 'ralewayregular';
    font-size: 1rem;
    color: $secondary-color;
  }
  .MuiSelect-select:not([multiple]) option {
    font-family: 'ralewayregular';
  }
  .MuiSelect-select:focus {
    background-color: transparent;
  }
  fieldset {
    @include border-radius(10px);
    font-size: 1.2rem;
  }
  .MuiInputBase-root {
    font-family: 'ralewayregular';
    font-size: 1rem;
  }
}
.text-box {
  width: 100%;
  fieldset {
    @include border-radius(10px);
    font-family: 'ralewayregular';
    font-size: 1.2rem;
  }
  .MuiFormLabel-root {
    font-family: 'ralewayregular';
  }
  .MuiInputBase-root {
    font-family: 'ralewayregular';
    font-size: 1rem;
  }
}

.number-input-box {
  width: 80%;
  fieldset {
    @include border-radius(10px);
    font-family: 'ralewayregular';
    font-size: 1.2rem;
  }
  .MuiFormLabel-root {
    font-family: 'ralewayregular';
  }
  .MuiInputBase-root {
    font-family: 'ralewayregular';
    font-size: 1rem;
  }
}


.fund-transfer-drop-down-box {
  width: 80%;
  .MuiFormLabel-root {
    font-family: 'ralewayregular';
    font-size: 1rem;
    color: $secondary-color;
  }
  .MuiSelect-select:not([multiple]) option {
    font-family: 'ralewayregular';
  }
  .MuiSelect-select:focus {
    background-color: transparent;
  }
  fieldset {
    @include border-radius(10px);
    font-size: 1.2rem;
  }
  .MuiInputBase-root {
    font-family: 'ralewayregular';
    font-size: 1rem;
  }
}

.file-filed {
  background-color: rgba($primary-color, 0.06);
  border: $secondary-color 2px dashed;
  @include border-radius(20px);
  width: 100%;
  height: 56px;
  font-size: 14px;
  text-transform: unset;
  font-weight: normal;
  .image-field-icon {
    font-size: 24px;
    color: $secondary-color;
    margin-right: 10px;
  }
}

.MuiButton-root {
  &.btn-delete-x {
    border: rgba($grey, 0.3) 1px solid;
    background-color: $white;
    color: $danger-color;
    height: 100%;
    box-shadow: none;
    font-family: 'ralewayregular';
    font-size: 1.6rem;
    @include border-radius(10px);
    &:hover {
      background-color: $white;
    }
  }
  &.btn-community-load-more {
    border: $primary-color 1px solid;
    color: $primary-color;
    font-size: 1rem;
    padding: 7px 14px;
    width: 84%;
    margin: 10px 8%;
    align-items: center;
    font-family: 'copperplate-bold';
    background-color: transparent;
    box-shadow: none;
    @include border-radius(10px);
    &:hover {
      background-color: $primary-color;
      color: $white;
    }
  }
  &.add-comment-channel {
    background-color: $primary-color;
    color: $white;
    font-size: 0.7rem;
    padding: 3px 14px;
    display: inline-flex;
    align-items: center;
    font-family: 'copperplate-bold';
    margin-left: 10px;
    @include border-radius(13px);
    &:hover {
      background-color: $grey;
    }
  }
  &.btn-community-trash {
    min-width: auto;
    padding: 5px;
    height: 24px;
    width: 24px;
    @include border-radius(50%);
    border: $community-button-grey 1px solid;
    box-shadow: none;
    text-align: center;
    color: $tooltip-color;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 0.625rem;
    background: transparent;
    margin-left: 10px;
    &:hover {
      background-color: $danger-color;
      border: $danger-color 1px solid;
      color: white;
    }
  }
  &.btn-community-edit {
    min-width: auto;
    padding: 5px;
    height: 24px;
    width: 24px;
    @include border-radius(50%);
    border: $community-button-grey 1px solid;
    box-shadow: none;
    text-align: center;
    color: $tooltip-color;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 0.625rem;
    background: transparent;
    margin-left: 10px;
    &:hover {
      background-color: $primary-color;
      border: $primary-color 1px solid;
      color: white;
    }
  }
  &.btn-community-report {
    min-width: auto;
    padding: 5px;
    height: 24px;
    width: 24px;
    @include border-radius(50%);
    border: $community-button-grey 1px solid;
    box-shadow: none;
    text-align: center;
    color: $tooltip-color;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 0.625rem;
    background: transparent;
    margin-left: 10px;
    &:hover {
      background-color: $orange;
      border: $orange 1px solid;
      color: white;
    }
  }
  &.btn-like {
    margin: auto;
    width: 28px;
    height: 28px;
  }
  &.btn-channel-trash {
    min-width: auto;
    padding: 5px;
    height: 24px;
    width: 24px;
    @include border-radius(50%);
    border: $circle-border 1px solid;
    box-shadow: none;
    text-align: center;
    color: $not-found-p;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 0.625rem;
    &:hover {
      background-color: $danger-color;
      border: $danger-color 1px solid;
      color: white;
    }
  }
  &.btn-channel-edit {
    min-width: auto;
    padding: 5px;
    height: 24px;
    width: 24px;
    @include border-radius(50%);
    border: $circle-border 1px solid;
    box-shadow: none;
    text-align: center;
    color: $not-found-p;
    font-size: 0.625rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    &:hover {
      background-color: $side-menu-active-color;
      border: $side-menu-active-color 1px solid;
      color: white;
    }
  }
  &.add-new-channel {
    background-color: $secondary-color;
    color: $white;
    font-size: 0.72rem;
    padding: 2px 14px;
    display: inline-flex;
    align-items: center;
    font-family: 'copperplate-bold';
    @media (max-width: 1460px) {
      font-size: 0.62rem;
    }
    @media (max-width: 1050px) {
      font-size: 0.52rem;
    }
    @include border-radius(13px);
    .channel-add-icon {
      margin-right: 5px;
    }
    &:hover {
      background-color: $grey;
    }
  }
  &.button-grey {
    color: $white;
    font-size: 1rem;
    font-weight: bold;
    text-transform: unset;
    padding: 0 20px;
    background-color: $secondary-color;
    font-family: 'copperplateregular';

    &:hover {
      background-color: $grey;
    }
  }

  &.button-red {
    color: $white;
    font-size: 1rem;
    font-weight: bold;
    text-transform: unset;
    padding: 0 20px;
    background-color: $danger-color;
    font-family: 'copperplateregular';

    &:hover {
      background-color: $danger-hover-color;
    }
  }

  &.button-disable {
    background-color: rgba($not-found-h, 0.5) !important;
    color: $white;
    opacity: 0.5;
    cursor: not-allowed !important;
    border: none !important;
  }
  &.button-cancel {
    height: 52px;
    @include border-radius(65px);
    border: $secondary-color 1px solid;
    color: $secondary-color;
    font-size: 1.125rem;
    font-weight: bold;
    text-transform: unset;
    padding: 0 20px;
    min-width: 170px;
    margin-right: 20px;
    font-family: 'copperplate-bold';
  }
  &.button-reset {
    height: 40px;
    @include border-radius(65px);
    border: $secondary-color 1px solid;
    color: $secondary-color;
    font-size: 1rem;
    text-transform: unset;
    padding: 0 20px;
    min-width: 150px;
    font-family: 'copperplate-bold';
  }
  &.button-back {
    height: 40px;
    @include border-radius(65px);
    border: $secondary-color 1px solid;
    color: $secondary-color;
    font-size: 1rem;
    text-transform: unset;
    padding: 0 20px;
    margin-right: 10px;
    font-family: 'copperplate-bold';
  }
  &.button-save {
    height: 52px;
    @include border-radius(65px);
    border: $success-dark-color 1px solid;
    color: $white;
    font-size: 1.125rem;
    font-weight: bold;
    text-transform: unset;
    padding: 0 20px;
    min-width: 170px;
    background: $success-dark-color;
    font-family: 'copperplate-bold';
    &:hover {
      background: $success-dark-hover-color;
      color: $white;
    }
  }
  &.button-save-disabled {
    height: 52px;
    @include border-radius(65px);
    border: $success-dark-color 1px solid;
    color: $white;
    font-size: 1.125rem;
    font-weight: bold;
    text-transform: unset;
    padding: 0 20px;
    min-width: 170px;
    background: rgba($grey, 0.3);
    font-family: 'copperplate-bold';
    &:hover {
      background: $success-dark-hover-color;
      color: $white;
    }
  }
}

.br-alert {
  text-align: center;

  .MuiDialog-paperScrollPaper {
    min-width: 30%;
    background-color: #f7f8fc;
  }

  .alert-cancel {
    background-color: $danger-color;
    color: $white;
    font-size: 14px;
    font-weight: bold;
    padding: 5px 15px;
    @include border-radius(4px);
    text-transform: unset;
    &:hover {
      background-color: rgba($danger-color, 0.8);
    }
  }

  .br-alert-icon {
    margin: 0 auto;
    width: 110px;
    height: 110px;
    display: inline-block;
    vertical-align: middle;
    background-color: $alert-grey;
    line-height: 110px;
    font-size: 48px;
    color: $danger-color;
    @include border-radius(50%);
    margin-bottom: 20px;
  }
  h3 {
    font-size: 20px;
  }
  p {
    font-size: 16px;
    color: $not-found-p;
  }
}

[role|='tooltip'] {
  div {
    background-color: $tooltip-color;
    color: $white;
  }
}
.br-preloader-backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba($black, 0.7);
  top: 0;
  left: 0;
  z-index: 2;

  .br-preloader {
    @include border-radius(50%);
    //border: $border-color 1px solid;
    @include box-shadow(0, 0px, 14px, rgba($black, 0.4));
    position: absolute;
    background-color: rgba($alert-grey, 0.9);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-image: url(../images/logo-inner.png);
    background-position: center;
    background-size: 60%;
    background-repeat: no-repeat;
    .MuiCircularProgress-root {
      vertical-align: middle;
      width: 60px !important;
      height: 60px !important;
      color: $logo-color;
    }
  }
}

.action-icons {
  display: inline-block;
  vertical-align: middle;
  width: 24px;
  height: 24px;
  background-color: $secondary-color;
  line-height: 24px;
  text-align: center;
  @include border-radius(50%);
  margin-right: 5px;
  cursor: pointer;
  color: $white;
  font-size: 12px;
  &:hover {
    background-color: $primary-color;
  }
}
.action-icons-delete {
  display: inline-block;
  vertical-align: middle;
  width: 24px;
  height: 24px;
  line-height: 23px;
  text-align: center;
  border: $danger-color 2px solid;
  @include border-radius(50%);
  cursor: pointer;
  color: $danger-color;
  font-size: 12px;
  &:hover {
    background-color: $danger-color;
    color: $white;
  }
}

.action-icons-star {
  display: inline-block;
  vertical-align: middle;
  width: 24px;
  height: 24px;
  line-height: 23px;
  text-align: center;
  background-color: $primary-color;
  @include border-radius(50%);
  cursor: pointer;
  color: $white;
  font-size: 12px;
  margin-right: 5px;
  &:hover {
    background-color: $primary-hover-color;
  }
}

.mt-24 {
  margin-top: 24px;
}
.image-add-field {
  background-color: rgba($primary-color, 0.06);
  @include border-radius(20px);
  display: inline-flex;
  align-items: stretch;
  height: 140px;
  width: 100%;
  overflow: hidden;
  margin-bottom: 25px;
  img {
    width: 100%;
    object-fit: cover;
  }
}
.upload-img {
  .img-filed {
    background-color: rgba($primary-color, 0.06);
    border: $secondary-color 2px dashed;
    @include border-radius(20px);
    width: 100%;
    height: 56px;
    font-size: 0.875rem;
    text-transform: unset;
    font-weight: normal;
    font-family: 'ralewayregular';
    .image-field-icon {
      font-size: 24px;
      color: $secondary-color;
      margin-right: 10px;
    }
  }
}

.m-top-20 {
  margin-top: 20px;
}

.details-header-right {
  display: flex;
  justify-content: flex-end;
}
.details-header-left {
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  .breadcrumb-details-header {
    position: absolute;
    top: 22px;
  }
}
.details-business-info-container {
  text-align: right;
}
@media only screen and (min-width: 0px) and (max-width: 600px) {
  .details-business-info-container {
    text-align: center;
    .details-business-info-box {
      max-width: 50%;
      width: 50%;
      padding: 15px;
      &:nth-child(1) {
        border-bottom: #ececed 1px solid;
      }
      &:nth-child(2) {
        border-right: none;
        border-bottom: #ececed 1px solid;
      }
    }
  }
}

@media only screen and (min-width: 601px) and (max-width: 1280px) {
  .details-business-info-container {
    display: flex;
    .details-business-info-box {
      width: auto;
      max-width: unset;
      flex-grow: 1;
    }
  }
}

.custom-tab {
  .MuiTab-root {
    text-transform: none;
    letter-spacing: normal;
  }
}

.add-document-modal {
  .MuiDialog-paper {
    width: 60%;
  }
}
.checkbox-document {
  padding: 5px 0;
  border-bottom: rgba($black, 0.1) 1px solid;
  font-size: 1rem;

  .MuiTypography-root {
    font-family: 'ralewayregular';
  }
}
.file-filed-document {
  background-color: rgba($primary-color, 0.06) !important;
  border: $primary-color 1px dashed !important;
  color: $secondary-color !important;
  padding: 30px !important;
  @include border-radius(10px);
  font-weight: normal !important;
  text-transform: unset !important;
  width: 80%;
  margin-bottom: 20px !important;
  left: 10%;
  > span {
    flex-direction: column;
    text-align: center;
  }
  .fas {
    color: $upload-color;
    font-size: 4rem;
    display: block;
    width: 100%;
  }
  div {
    display: block;
  }
  .file-filed-text-size-sm {
    font-size: 0.8rem;
    padding: 15px 0;
  }
  .file-filed-text-size-md {
    font-size: 1.05rem;
    line-height: 100%;
  }
  .file-filed-text-size-highlight {
    font-size: 1rem;
    font-weight: bold;
    color: $primary-color;
  }
}
.document-tags {
  margin: 20px auto;
  .MuiInputLabel-root {
    color: $secondary-color;
    font-size: 1rem;
    font-family: 'ralewayregular';
  }
  .MuiInputBase-root {
    flex-direction: column;
    align-items: flex-start;
  }
  .MuiAutocomplete-tag {
    font-family: 'ralewayregular';
    background-color: $alert-grey;
  }
  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
    padding: 20px;
  }
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border: rgba($black, 0.1) 1px solid;
  }
  .MuiOutlinedInput-notchedOutline {
    @include border-radius(10px);
    border: rgba($black, 0.05) 1px solid;
  }
  .MuiChip-deleteIcon {
    color: $tooltip-color;
  }
  .MuiChip-root.MuiChip-deletable {
    background: rgb(237, 242, 247) !important;
  }
  .MuiChip-label {
    padding-left: 6px;
    color: rgba(0, 0, 0, 0.87);
  }
  .MuiChip-root.MuiChip-deletable {
    margin: 3px;
  }
}
.select-members-name {
  display: inline-block;
  vertical-align: middle;
  margin-top: 5px;
  line-height: 12px;
}
.select-members-icon-disabled {
  color: #798897 !important;
  padding: 3px !important;
}
.select-members-icon {
  color: #3f51b5 !important;
  padding: 3px !important;
}

.header-filter-box {
  .view-icon {
    display: inline-flex;
    i {
      margin-left: 10px;
    }
  }
}
.header-filter-box-search-add {
  display: flex;
  align-items: stretch;
  width: 100%;
  position: relative;
  @media (max-width: 600px) {
    flex-direction: column;
  }
  .header-filter-box-add {
    display: flex;
    padding-left: 10px;
    @media (max-width: 600px) {
      justify-content: center;
      padding-bottom: 20px;
    }
  }
  .header-filter-box-manage-types {
    display: flex;
    padding-left: 10px;
    @media (max-width: 600px) {
      justify-content: center;
      padding-bottom: 20px;
    }
  }
  .header-filter-box-search {
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    position: relative;
    white-space: nowrap;
    @media (max-width: 600px) {
      display: flex;
      width: auto;
      justify-content: center;
      order: 2;
    }
    @media screen and (max-width: 720px) {
      .page-search-box {
        width: auto;
      }
    }
  }
}

.header-filter-box-left {
  display: flex;
  align-items: center;
  @media screen and (max-width: 959px) {
    justify-content: space-between;
  }
}
.side-bar-container {
  @media (max-width: 959px) {
    // display: none;
  }
}
// .side-bar-container {
//   @media (max-width: 959px) {
//     position: absolute;
//     &::before {
//       content: "menu";
//       position: absolute;
//       display: inline-block;
//       padding: 20px;
//       z-index: 100;
//     }
//   }
// }
.dialog-box {
  padding-top: 20px;
  font-family: 'ralewayregular';
  // .MuiDialogContent-root {
  //   overflow: auto;
  // }
  .MuiDialogTitle-root {
    padding: 20px 40px;
    @include box-shadow(0px, 1px, 5px, rgba($black, 0.07));
  }
  @media (max-width: 600px) {
    padding-top: 0;
  }
  .MuiDialog-paperScrollPaper {
    min-width: 55%;
    background-color: $bgColor;
    position: relative;
    @media (max-width: 600px) {
      min-width: 100%;
      max-height: 100%;
      height: 100%;
      @include border-radius(0);
    }
  }

  .modal-buttons-container {
    display: flex;
    width: 100%;
    > div {
      padding: 5px 10px;
      &:first-child {
        display: flex;
      }
      &:last-child {
        text-align: right;
        flex: 1;
        width: 1%;
      }
    }
    .go-back {
      display: inline-block;
      vertical-align: middle;
      color: $not-found-p;
      cursor: pointer;
      margin-top: 8px;
      .back-icon {
        font-size: 14px;
      }
      &:hover {
        color: #58646f;
      }
    }
    .create-user-button {
      border: $not-found-p 1px solid;
      @include border-radius(4px);
      font-size: 13px;
      color: $not-found-p;
      background: transparent;
      padding: 8px 25px;
      cursor: pointer;
      display: inline-block;
      vertical-align: middle;
      font-weight: 700;
      &:hover {
        background-color: $border-color;
      }
    }
    .back-to-assign-button {
      border: none;
      font-size: 13px;
      color: $grey;
      background: transparent;
      padding: 5px 25px;
      cursor: pointer;
      display: inline-block;
      vertical-align: middle;
      font-weight: bold;
      i {
        margin-right: 10px;
      }
      &:hover {
        color: $black;
      }
    }
    .invite-user-button {
      border: $not-found-p 1px solid;
      @include border-radius(4px);
      font-size: 1rem;
      color: $white;
      background: $success-dark-color;
      padding: 4px 24px;
      cursor: pointer;
      text-transform: unset;
      display: inline-block;
      vertical-align: middle;
      min-width: 123px;
      font-family: 'copperplate-bold';
      &:hover {
        background-color: $success-dark-hover-color;
      }
      height: 40px;
    }
    .export-button {
      border: $not-found-p 1px solid;
      @include border-radius(4px);
      font-size: 1rem;
      color: $white;
      background: $primary-color;
      padding: 4px 24px;
      cursor: pointer;
      text-transform: unset;
      display: inline-block;
      vertical-align: middle;
      min-width: 123px;
      font-family: 'copperplate-bold';
      &:hover {
        background-color: $primary-hover-color;
      }
      height: 40px;
    }
    .cancel-user-button {
      border: none;
      @include border-radius(4px);
      font-size: 1rem;
      color: $danger-color;
      background: none;
      padding: 3px 14px;
      cursor: pointer;
      text-transform: unset;
      display: inline-block;
      vertical-align: middle;
      text-decoration: underline;
      font-family: 'copperplate-bold';
      &:hover {
        color: $danger-hover-color;
      }
    }
  }

  .MuiTypography-h6 {
    font-family: 'copperplate-bold';
    color: $primary-color;
  }
  h3 {
    font-family: 'ralewaybold';
    margin-bottom: 10px;
  }
  .MuiIconButton-root {
    width: 36px;
    height: 36px;
    font-size: 1.2rem;
  }
}

.edit-doc-section {
  margin: 0px auto;
  font-family: 'ralewayregular';
  .edit-doc-section-box {
    background-color: rgba($primary-color, 0.06);
    @include border-radius(10px);
    padding: 15px;
    width: 80%;
    margin: 0 auto;
    text-align: center;
    height: 100%;
    .edit-doc-section-name {
      font-size: 1rem;
      color: $file-name-color;
      word-wrap: break-word;
    }
    .edit-doc-section-size {
      font-size: 0.7rem;
      color: $secondary-color;
      font-family: 'copperplate-bold';
      padding: 10px 0;
    }
    .edit-doc-section-buttons {
      display: flex;
      margin: 20px auto;
      justify-content: center;
      flex-wrap: wrap;
      .remove-button {
        background-color: none;
        color: $danger-color;
        text-transform: unset;
        font-family: 'copperplate-bold';
        text-decoration: underline;
        margin: 5px 3px;
        font-size: 1rem;
      }
      .replace-button {
        background-color: $logo-color;
        color: $white;
        text-transform: unset;
        font-family: 'copperplate-bold';
        padding: 10px 15px 5px 15px;
        margin: 5px 3px;
        cursor: pointer;
        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
          box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
          border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        @include border-radius(5px);
        &:hover {
          background-color: $logo-color-dark;
        }
      }
    }
  }
  .edit-doc-section-input {
    padding: 10px 0;
    margin: 10px auto;
    width: 80%;
  }
  .edit-doc-section-text-area {
    width: 100%;
    .doc-text-area {
      height: 10vh;
    }
  }
  .edit-doc-section-select {
    padding: 10px 0;
    margin: 10px auto;
    width: 80%;
  }
}

.select-box-general {
  width: 100%;
  @include border-radius(10px);
  border: rgba($black, 0.1) 1px solid;
  padding: 7px 10px;
  &.MuiInput-underline::before {
    border-bottom: none;
  }
  &.MuiInput-underline:hover:not(.Mui-disabled)::before {
    border-bottom: none;
  }
  &.MuiInput-underline::after {
    border-bottom: none;
  }
  .MuiSelect-select:focus {
    background-color: transparent !important;
  }
}
.input-with-icons {
  position: relative;
  i {
    position: absolute;
    top: 2px;
    right: 0px;
    height: 90%;
    max-height: 52px;
    display: flex;
    margin-right: 2px;
    align-items: center;
    justify-content: center;
    width: 34px;
    @include border-radius(7px);
    background: $bgColor;
    color: $yellowGrey;
  }
  &.pos-50 {
    i {
      right: 21px;
    }
  }
}
.form-element-container {
  margin-bottom: 20px;
}

.radio-group-element {
  flex-direction: row !important;
  .MuiFormControlLabel-root {
    width: 45%;
  }
}
.MuiAccordion-root {
  &.Mui-expanded {
    margin: 0 !important;
    background-color: $side-menu-active-color;
    .side-bar-item {
      span {
        font-family: 'copperplate-bold';
      }
    }
  }
  .MuiIconButton-root {
    color: $white;
    margin-right: 0px;
    padding: 8px;
  }
  &.side-menu-accordion {
    background: transparent;
    box-shadow: none;
    font-family: 'copperplateregular';
    .MuiAccordionSummary-root {
      padding: 0;
      min-height: auto;
      .MuiAccordionSummary-content {
        margin: 0;
        position: relative;
        //padding-left: 35px;
      }
    }
    .MuiAccordionDetails-root {
      flex-direction: column;
    }
    .MuiAccordionDetails-root {
      //padding: 0 16px 16px;
      padding: 0;
    }
    .side-menu-expand-list {
      //padding: 10px 0px;
      margin-left: 25px;
      width: 100%;
      display: block;
      color: $white;
      font-family: "copperplateregular";
      font-size: 14px;
      cursor: pointer;
      border-bottom: rgba($white, 0.1) 1px solid;
      &:last-child {
        border-bottom: none;
      }
    }
    &::before {
      background-color: transparent;
    }
  }
}

.freeze-text-box {
  width: 100%;
  .MuiOutlinedInput-root {
    background-color: $text-auto-fill-color;
    @include border-radius(10px);
  }
  label {
    background-color: $text-auto-fill-border;
  }
  fieldset {
    @include border-radius(10px);
    font-family: 'ralewayregular';
    font-size: 1.2rem;
    border: rgba($secondary-color, 0.3) 1px solid;
  }
}

.section-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  > span {
    padding-left: 0 !important;
    .MuiCircularProgress-root {
      display: inline-block;
      vertical-align: middle;
    }
  }
}

.button-spinner-wrapper {
  position: relative;
  display: inline-flex;
  align-items: center;
  .button-spinner {
    position: absolute;
    right: -20px;
    top: 50%;
    transform: translateY(-50%);
    display: inline-flex;
    align-items: center;
    > span {
      padding-left: 0px;
      color: white;
      display: inline-flex;
      align-items: center;
    }
  }
}

.span-disabled {
  pointer-events: none;
}
.br-tab {
  .MuiButtonBase-root {
    font-family: 'ralewayregular';
    text-transform: unset;
    font-size: 1rem;
    color: $black;
  }
  .Mui-selected {
    font-family: 'ralewaybold';
    color: $primary-color;
  }
  .MuiTabs-indicator {
    background-color: $primary-color;
    height: 2px;
  }
  .MuiBox-root {
    padding: 30px 0;
  }
}
.br-dropdown-menu {
  position: relative;
  z-index: 1;
  .MuiButton-root {
    border: $border-color 1px solid;
    background-color: $white;
    padding: 5px 10px;
    font-size: 0.875rem;
    text-transform: unset;
    .drop-menu-button-text {
      font-family: 'ralewayregular';
      max-width: 300px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .drop-menu-button-number {
      font-family: 'copperplate-bold';
      margin-left: 10px;
    }
    .fa-chevron-down {
      font-size: 0.6rem;
      color: rgba($yellowGrey, 0.4);
      margin-left: 10px;
    }
  }
  > div {
    width: 100%;
  }
  .MuiList-root {
    background-color: #fff;
    width: 100%;
    padding: 10px;
    @include box-shadow(-5px, 5px, 10px, rgba($black, 0.2));
    .MuiMenuItem-root {
      font-size: 0.875rem;
      font-family: 'copperplateregular';
      color: $yellowGrey;
      border-bottom: $alert-grey 1px solid;
      padding: 10px;
      // > span {
      //   color: $danger-color;
      //   font-family: "ralewaybold";
      //   margin-left: 5px;
      // }
    }
  }
}
.br-checkbox {
  display: flex;
  align-items: center;
  padding: 5px 7px;
  font-family: 'ralewayregular';
  font-size: 0.875rem;
  margin: 5px 0;
  .MuiCheckbox-colorSecondary.Mui-checked {
    color: $primary-color;
  }
  .MuiIconButton-root {
    background-color: transparent;
  }
  .MuiSvgIcon-root {
    font-size: 1.1rem;
  }
  .MuiCheckbox-root {
    width: auto;
    height: auto;
    padding: 0;
    margin-right: 5px;
  }
}
@media (min-width: 960px) {
  .side-bar-container {
    transition: 0.5s;
    &.side-bar-container-desktop-collapse {
      width: 50px;
      transition: 0.5s;
      overflow: unset;
      // @media (max-width:959px) {
      //   left: -100%;
      // }
      .MuiAccordionSummary-content {
        padding: 0 !important;
      }
      .side-bar-item {
        display: flex;
        justify-content: center;
        span {
          width: 0;
          opacity: 0;
          overflow: hidden;
        }
        i {
          opacity: 1;
          text-align: center;
        }
      }
      .side-menu-accordion {
        position: relative;
        .MuiIconButton-root {
          display: none;
        }
        .MuiIconButton-label {
          display: none;
        }
        .MuiCollapse-root {
          position: absolute;
          top: 0;
          left: 100%;
          background-color: $side-menu-active-color;
          z-index: 1;
          .MuiAccordionDetails-root {
            padding: 10px;
            .side-menu-expand-list {
              margin-left: 0;
              white-space: nowrap;
            }
          }
        }
      }
    }
    .active-menu {
      background-color: $side-menu-active-color !important;
    }
  }
}
.side-menu-close-icon {
  position: absolute;
  top: 15px;
  right: 15px;
  color: $white;
  z-index: 1;
  padding: 4px 6px;
  cursor: pointer;
  display: none;
}

@media (max-width: 959px) {
  .side-bar-container {
    position: absolute;
    z-index: 4;
    top: 0;
    left: 0;
    height: 100%;
    transition: 0.5s;
    .side-menu-close-icon {
      display: inline-block;
    }

    &.side-bar-container-mobile-collapse {
      left: 0;
    }
    &.side-bar-container-mobile-close {
      left: -100%;
    }
  }
}

@media (max-width: 959px) {
  .side-menu-arrow {
    display: none;
  }
}
.mobile-menu-icon {
  font-size: 1.125rem;
  color: $primary-color;
  transition: 0.5s;
  position: relative;
  cursor: pointer;
  padding: 5px;
  display: none;
  @media (max-width: 959px) {
    display: inline-block;
  }
}
.date-picker {
  width: 100%;
  margin: 0 !important;
  .MuiInputBase-root {
    border: rgba($black, 0.4) 1px solid;
    border-radius: 10px;
    padding: 11px 10px;
  }
  .MuiInput-underline::before,
  .MuiInput-underline::after {
    border-bottom: none;
  }
  .MuiInput-underline:hover:not(.Mui-disabled)::before {
    border-bottom: none;
  }
  .MuiFormLabel-root {
    top: 10px;
    left: 10px;
    background-color: $bgColor;
    z-index: 1;
    padding: 0 5px;
    &.Mui-focused {
      top: 8px;
    }
    &.MuiInputLabel-shrink {
      top: 8px;
    }
  }
}

.documents-grid-img {
  color: $secondary-color;
  font-size: 3rem;
  text-align: center;
  position: relative;
  display: inline-block;
  margin-bottom: 20px;
  .documents-doc-type {
    color: $white;
    @include border-radius(5px);
    font-size: 0.6rem;
    padding: 2px 4px;
    position: absolute;
    display: inline-block;
    bottom: 0px;
    left: -10px;
    font-family: 'ralewaybold';
    line-height: 100%;
    &.doc-pdf {
      background-color: $orange;
    }
    &.doc-xls {
      background-color: $xls-bg-color;
    }
    &.doc-type {
      background-color: $success-dark-color;
    }
  }
}

.details-title-draft {
  color: $danger-color;
  padding-left: 5px;
}
.details-title-in-progress {
  color: $primary-hover-color;
  padding-left: 5px;
}
.details-title-published {
  color: $success-color;
  padding-left: 5px;
}
// header responsive style
@media (max-width: 668px) {
  .logo-wrap {
    img {
      height: 25px !important;
    }
  }
  .makeStyles-profileRoot-3 {
    height: 100%;
    .makeStyles-dropdown-9 {
      top: unset;
      right: 10px;
      z-index: 3;
    }
  }
  .nav-warp {
    justify-content: space-between;
    .side-nav {
      width: auto;
      height: 100%;
      .logo-wrap {
        height: 100%;
      }
    }
    .nav-header {
      width: auto;
      height: 100%;
      .nav-item {
        height: 100%;
        width: 50px;
      }
    }
    .nav-item-dropdown {
      height: 100% !important;
      i {
        display: none;
      }
      .nav-admin-options span {
        display: none;
      }
    }
  }
  .details-header {
    min-height: 120px;
  }
}
.step-nav {
  width: 100%;
  .MuiPaper-root {
    background-color: unset;
  }
  .MuiStepper-root {
    padding: 10px 0;
  }
  .step-content {
    padding: 10px 0;
    text-align: center;
    .MuiTypography-root {
      padding: 0px 10px 15px 10px;
    }
    .documents-grid-img {
      margin-bottom: 15px;
    }
  }
  .step-footer {
    padding: 10px;
    text-align: right;
  }
  .MuiStepIcon-active {
    color: $primary-color !important;
  }
  .MuiStepIcon-completed {
    color: $primary-hover-color !important;
  }

  .rebates-file-upload {
    width: 50% !important;
    margin: auto;
    .edit-doc-section-box {
      padding: 10px !important;
    }
    .file-filed-document {
      padding: 15px !important;
    }
  }
  .step-content-wrapper {
    position: relative;
  }
  .step-footer {
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px 80px;
    top: 50%;
    transform: translateY(-50%);
  }
  .step-footer {
    .MuiButton-root {
      min-width: 35px !important;
      padding: 5px !important;
      .arrow-left {
        margin: 4px 2px 0 0;
      }
      .arrow-right {
        margin: 4px 0 0 2px;
      }
    }
    .button-back {
      &.button-disable {
        color: $white;
      }
    }
  }
  .MuiStepLabel-label {
    color: #55606a75;
    font-weight: 600;
  }
  .MuiStepLabel-label.MuiStepLabel-completed {
    color: $primary-color;
    font-weight: 600;
  }
  .MuiStepLabel-label.MuiStepLabel-active {
    font-weight: 600;
  }
}
.active-table-row {
  background: rgba(86, 204, 242, 0.1) !important;
}
.br-number-normal {
  font-family: 'copperplateregular';
}
.br-number-bold {
  font-family: 'copperplate-bold';
}
.br-align-right {
  text-align: right;
}
.br-block-100 {
  display: block;
  width: 100%;
}
.dropdown-spinner {
  padding-left: 5px;
  margin-top: 4px;
}
.table-header-right {
  text-align: right;
  padding-right: 30px;
}
.table-header-center {
  text-align: center;
}
.table-data-spacing {
  padding-right: 30px;
  white-space: nowrap;
}
.table-data-nowrap {
  white-space: nowrap;
}
.info-table-header-right {
  text-align: right;
}
p {
  line-height: 140%;
}
.filter-form-header {
  justify-content: flex-end;
  margin-bottom: 10px;
  .MuiGrid-root {
    &:nth-child(1) {
      @media (max-width: 600px) {
        order: 2;
        width: 100%;
      }
    }
    &:nth-child(2) {
      padding-left: 10px;
      text-align: right;
      width: 184px;
      .MuiButtonBase-root {
        width: 100%;
        text-align: center;
      }
      @media (max-width: 600px) {
        order: 1;
        width: 100%;
        padding-left: 0px;
        padding-bottom: 10px;
        text-align: center;
        .MuiButtonBase-root {
          width: auto;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .MuiTablePagination-root {
    .MuiToolbar-gutters {
      padding-left: 0;
    }
    .MuiTablePagination-selectRoot {
      margin-right: 5px;
      margin-left: 5px;
    }
    .MuiTablePagination-select {
      padding-right: 16px !important;
      padding-left: 4px;
    }
    .MuiSelect-icon {
      right: -3px;
    }
    .MuiTablePagination-actions {
      margin-left: 0;
    }
    .MuiIconButton-root {
      padding: 2px;
    }
  }
}

.login-wrapper {
  @media (max-width: 900px) {
    .login-bg {
      width: 50%;
      .features-info {
        width: 90%;
      }
      .login-content {
        img {
          max-width: 50%;
        }
      }
    }
    .login-form {
      width: 50%;
    }
  }
  @media (max-width: 600px) {
    height: auto;
    flex-direction: column;
    .login-bg {
      width: 100%;
      height: auto;
      padding: 30px 30px 10px 30px;
      background-color: transparent;
      .features-info {
        width: 100%;
        height: auto;
        p {
          margin: 10px 0;
          font-size: 1.1rem;
        }
        h3 {
          font-size: 1.2rem;
        }
        .login-content {
          width: 100%;
          img {
            max-width: 50%;
          }
          .logo-forgot-password {
            mix-blend-mode: difference;
            opacity: 0.8;
          }
        }
      }
    }
    .login-form {
      width: 100%;
      height: auto;
      padding: 10px 30px 30px 30px;
      form {
        width: 100%;
      }
    }
  }
  .MuiSelect-icon {
    right: -3px;
  }
  .MuiTablePagination-actions {
    margin-left: 0;
  }
  .MuiIconButton-root {
    padding: 2px;
  }
}

.mobile-table-label {
  color: $secondary-color;
  display: none !important;
  padding: 10px;
  // width: 150px;
  margin-right: 10px;
  @media (max-width: 1280px) {
    display: inline-block !important;
    vertical-align: middle;
  }
  .grid-header {
    height: auto !important;
    text-align: left;
  }
  .grid-header-no-border {
    border-bottom: none !important;
  }
}
.table-data-box {
  @media (max-width: 1280px) {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    .table-data-text {
      text-align: right;
      padding: 10px;
      @media (width: 1280px) {
        text-align: left !important;
      }
    }
  }
}
.br-table-data-ellipse {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.legend-label {
  position: relative;
  > div {
    display: inline-block;
    vertical-align: middle;
    margin: 0px;
  }
  .icon-within-terms {
    width: 10px;
    height: 8px;
    background-color: $inside-terms-color;
    display: inline-block;
    vertical-align: middle;
  }
  .text-within-terms {
    padding-left: 4px;
    font-size: 0.75rem;
    color: $inside-terms-color;
    display: inline-block;
    vertical-align: middle;
  }
  .icon-outside-terms {
    width: 10px;
    height: 8px;
    background-color: $outside-terms-color;
    display: inline-block;
    vertical-align: middle;
  }
  .text-outside-terms {
    padding-left: 4px;
    font-size: 0.75rem;
    color: $outside-terms-color;
    display: inline-block;
    vertical-align: middle;
  }
  .legend-icon-below {
    width: 10px;
    height: 8px;
    background-color: $drop-size-color;
    display: inline-block;
    vertical-align: middle;
  }

  .legend-text-below {
    padding-left: 4px;
    font-size: 0.75rem;
    color: $drop-size-color;
    display: inline-block;
    vertical-align: middle;
  }
  .legend-icon-above {
    width: 10px;
    height: 8px;
    background-color: $success-dark-color;
    display: inline-block;
    vertical-align: middle;
  }

  .legend-text-above {
    padding-left: 4px;
    font-size: 0.75rem;
    color: $success-dark-color;
    display: inline-block;
    vertical-align: middle;
  }
  .legend-icon-within {
    width: 10px;
    height: 8px;
    background-color: $inside-terms-color;
    display: inline-block;
    vertical-align: middle;
  }

  .legend-text-within {
    padding-left: 4px;
    font-size: 0.75rem;
    color: $inside-terms-color;
    display: inline-block;
    vertical-align: middle;
  }
}

.action-header {
  text-align: right;
}
.br-conditions-text {
  font-size: 0.7rem;
}
.br-copyrights {
  border-top: rgba($white, 0.2) 1px solid;
  color: rgba($white, 0.6);
  padding: 10px 5px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 0.75rem;
  font-family: 'copperplateregular';
  > span {
    padding: 0px 5px;
    &:nth-child(2) {
      font-family: 'copperplate-bold';
    }
  }
}
.br-tos {
  // border-top: rgba($white, 0.2) 1px solid;
  color: rgba($white, 0.6);
  padding: 5px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 0.75rem;
  font-family: 'copperplateregular';
  > span {
    padding: 0px 5px;
    > a {
      color: rgba($white, 0.6);
    }
  }
}
.side-menu-section {
  flex: 1;
}
.file-upload-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.MuiMenu-list {
  padding: 0px !important;
  option {
    padding: 10px 15px;
    border-bottom: rgba($black, 0.05) 1px solid;
    &:empty {
      padding: 0;
      display: none;
    }
  }
}

.btn-mobile {
  @media (max-width: 420px) {
    width: 100% !important;
    margin-bottom: 20px !important;
  }
}

.phone-link {
  color: black;
}

.email-link {
  color: #fff;
}

.member-dropdown-menu-list {
  max-height: 40vh;
  overflow: auto;
}

.account-notes {
  font-family: 'ralewayregular';
  font-size: 12px;
  font-weight: 600;
}

.dwolla-terms {
  font-family: 'ralewayregular';
  font-size: 14px;
}

.member-email {
  font-size: 12px;
  color: $email-color;
  cursor: pointer;
  font-weight: normal;
  font-family: "copperplateregular" !important;
  overflow-wrap: anywhere;
  > a  {
    color: black;
  }
}

.tos-content {
  margin : 40px;
}

.checkbox-handDeliver {
  display: flex;
  align-items: center;
  padding: 5px 7px;
  font-family: 'ralewayregular';
  font-size: 0.875rem;
}
