@font-face {
  font-family: "robotobold";
  src: url("../webfonts/roboto-bold-webfont.woff2") format("woff2"),
    url("../webfonts/roboto-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "robotolight";
  src: url("../webfonts/roboto-light-webfont.woff2") format("woff2"),
    url("../webfonts/roboto-light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "robotomedium";
  src: url("../webfonts/roboto-medium-webfont.woff2") format("woff2"),
    url("../webfonts/roboto-medium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "robotoregular";
  src: url("../webfonts/roboto-regular-webfont.woff2") format("woff2"),
    url("../webfonts/roboto-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "copperplate-light";
  src: url("../webfonts/coprgtl.woff2") format("woff2"),
    url("../webfonts/coprgtl.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "copperplateregular";
  src: url("../webfonts/copperplate_regular.woff2") format("woff2"),
    url("../webfonts/copperplate_regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "copperplate-bold";
  src: url("../webfonts/coprgtb.woff2") format("woff2"),
    url("../webfonts/coprgtb.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "ralewaybold";
  src: url("../webfonts/raleway.bold.woff2") format("woff2"),
    url("../webfonts/raleway.bold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "ralewaylight";
  src: url("../webfonts/raleway.light.woff2") format("woff2"),
    url("../webfonts/raleway.light.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "ralewayregular";
  src: url("../webfonts/raleway.regular.woff2") format("woff2"),
    url("../webfonts/raleway.regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "ralewaysemibold";
  src: url("../webfonts/raleway-semibold.woff2") format("woff2"),
    url("../webfonts/raleway-semibold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
