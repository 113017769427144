@import "../../../scss/variables-mixin.scss";

.page-notfound {
  height: 100%;
  .page-not-found-text {
    font-size: 24px;
    color: $black;
  }
  .page-not-found-home {
    color: $primary-color;
    font-size: 16px;
    margin: 10px 0;
  }
  .not-found-back {
    color: inherit;
    text-decoration: underline;
  }
}
