@import "../../../scss/variables-mixin.scss";

.user-notfound {
  height: 100%;
  img{
    width: 363px;
    height: 316px;
  }
  .user-not-found-text {
    font-size: 24px;
    margin-top: 0.25rem;
    color: $black;
  }
  .user-not-found-home {
    color: $primary-color;
    font-size: 16px;
    margin: 10px 0;
  }
  .not-found-back {
    color: inherit;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
