@import "../../scss/variables-mixin.scss";

.table-wrapper {
  .grid-header {
    cursor: pointer;
  }
  .sort-icon {
    font-weight: 200;
    font-size: 12px;
    padding-left: 5px;
    .sort-svg {
      display: inline-block;
      vertical-align: middle;
      width: 8px;
    }
  }
  .kpi-status {
    color: #e15759;

    padding-bottom: -20px;
    .inactive-link {
      margin-top: -3px;
      font-weight: 600;
    }
    :hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.vendor-member-text-box {
  margin-bottom: 25px;
}

.table-wrapper {
  .header-filter-box-search {
    margin-bottom: 25px;
  }
  .assign-member-filters {
    margin-bottom: 20px;
  }
  .table-member-details {
    .member-profile-details {
      padding-left: 10px;
    }
    .member-name {
      margin-bottom: 5px;
    }
  }
}

.member-dc-label {
  padding: 10px;
  span {
    color: $primary-color;
    font-family: "ralewaybold";
  }
}
