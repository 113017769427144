@import "../../scss/variables-mixin.scss";
// community main layout
.community {
  display: flex;
  height: 100%;
  .community-category {
    display: flex;
    flex-direction: column;
    width: 350px;
    background-color: $white;
    height: 100%;
    overflow: auto;
    position: relative;
    z-index: 1;
    @include box-shadow(rgba($black, 0.1), 0px, 4px, 4px);
  }
  .community-section {
    width: 100%;
    padding: 20px 28px;
    height: 100%;
    overflow: auto;
    position: relative;
    @media (max-width:600px)
    {
      padding: 20px;
      position: absolute;
      top: 0;
      right: 0;
      background:#fff;
      z-index: 0;
      &.community-section-visible
      {
        z-index: 2;
      }
    }
  }
  @media (max-width:600px)
  {
    flex-direction: row;
    flex-wrap: wrap;
    .community-category
    {
      width: 100%;
    }
  }
}

// community category header
.community-category-header {
  display: flex;
  position: relative;
  align-items: center;
  padding: 20px;
  .community-left-heading {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    font-family: "copperplate-bold";
    font-size: 1.125rem;
    color: $primary-color;
    @media(max-width: 1460px){
      font-size: 1rem;
    }
    @media(max-width: 1050px){
      font-size: .8rem;
    }
  }
  .community-add-new-channel {
    display: flex;
    padding-right: 7px;
  }
  .community-channel-search {
    display: flex;

    .community-channel-search-box {
      width: 30px;
      height: 30px;
      @include border-radius(50%);
      border: $circle-border 1px solid;
      padding: 4px;
      align-items: center;
      display: flex;
      justify-content: center;
      color: $secondary-color;
      .fa-search {
        font-size: 0.75rem;
      }
      &:hover {
        cursor: pointer;
        background-color: #e9e9e9;
      }
      .fa-times {
        display: none;
      }
      input {
        display: none;
      }
    }

    .community-channel-search-box-click {
      width: 100%;
      height: 50px;
      left: 0;
      top: 0;
      background-color: $white;
      @include border-radius(0);
      border: none;
      border-bottom: $circle-border 1px solid;
      padding: 4px;
      align-items: center;
      display: flex;
      justify-content: center;
      position: absolute;
      color: $secondary-color;
      padding: 0 15px;
      .fa-search {
        font-size: 1.2rem;
      }
      .fa-times {
        font-size: 0.75rem;
        cursor: pointer;
        &:hover {
          cursor: pointer;
          color: black;
        }
      }

      input {
        width: 100%;
        height: 100%;
        padding: 5px;
        border: none;
        font-size: 1rem;
        &:focus-visible {
          border: none;
          box-shadow: none;
          outline: none;
        }
      }
    }
  }
}

// community channel list
.community-channel-list {
  margin: 0;
  flex-grow: 1;
  height: 100%;
  overflow: auto;
  position: relative;
  .category-list-spinner {
    min-height: 570px;
    background-color: transparent;
  }
  .community-channel-list-box {
    padding: 15px 20px;
    border-right: transparent 3px solid;
    position: relative;
    cursor: pointer;
    h2 {
      font-size: 1rem;
      color: $file-name-color;
      margin-bottom: 10px;
      .community-channel-notification {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        line-height: 20px;
        color: $white;
        background-color: $success-dark-color;
        font-family: "copperplateregular";
        font-size: 0.625rem;
        margin-left: 5px;
      }
    }
    p {
      font-size: 0.875rem;
      color: $not-found-p;
      margin: 0;
      line-height: 160%;
    }
    .community-channel-action-buttons {
      position: absolute;
      top: 10px;
      right: 10px;
      display: none;
    }
    &:hover {
      background-color: $community-light-grey;
      .community-channel-action-buttons {
        display: inline-block;
      }
    }
    &.community-channel-active {
      background-color: $active-community-channel;
      border-right: $success-dark-color 3px solid;
      h2 {
        color: $success-dark-color;
      }
      p {
        font-size: 0.875rem;
        color: $not-found-p;
        margin: 0;
        line-height: 160%;
      }
    }
    .btn-channel-trash,
    .btn-channel-edit {
      margin-left: 5px;
    }
  }
  .channel-notfound {
    display: flex;
    justify-content: center;
  }
}
// community header
.community-header {
  .community-header-left {
    text-align: left;
  }
  .community-header-right {
    text-align: right;
    .page-search-box
      {
        .fa-times
        {
          color: $secondary-color;
          font-size: 1rem;
          cursor: pointer;
          display: none;
        }
      }
    @media (max-width:960px){
      text-align: center;
      margin-top: 10px;
      margin-bottom: 10px;
      .page-head
      {
        padding-left: 0;
        margin: 5px;
      }
    }
    @media (max-width:600px){
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      .btn-add
      {
        padding: 8px !important;
      }
      .page-head:nth-child(1)
      {
        order: 2;
      }
      .page-head:nth-child(2)
      {
        order: 1;
      }
      .page-search-box
      {
        margin-top: -3px;
        .MuiInputBase-root
        {
          width: 0;
          padding: 0;
        }
        .fa-search
        {
          padding: 0;
          cursor: pointer;
        }
        &.page-search-box-visible
        {
          position: absolute;
          width: 96% !important;
          left: 2%;
          border: none;
            .MuiInputBase-root
          {
            width: 100%;
            padding: 10px;
          }
          .fa-times
          {
            display: inline-block;
          }
        }
      }
    }
  }
  .community-title {
    font-family: "copperplate-bold";
    font-size: 1.125rem;
    .fa-arrow-left
    {
      color:$secondary-color;
      font-size: 0.75rem;
      margin-right: 5px;
      padding: 5px;
      cursor: pointer;
      display: none;
    }
    @media (max-width:600px)
    {
      color: $primary-color;
      font-size: 1rem;
      .fa-arrow-left
      {
        display: inline-block;
        vertical-align: middle;
      }
    }
    @media (max-width:480px)
    {
      font-size: .95rem;
    }
  }
}
// community questions
.community-questions-back-previous {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $not-found-p;
}
.community-questions-back-previous-button {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  .fa-arrow-left {
    margin-right: 10px;
  }
  .fa-arrow-right {
    margin-left: 10px;
  }
  &:hover {
    color: $black;
    transition: 0.4s;
  }
}
.community-questions {
  margin: 20px auto;
  background-color: $white;
  @include border-radius(10px);
  @include box-shadow(rgba($black, 0.1), 0px, 1px, 4px);
  padding: 20px;
  display: flex;
  position: relative;
  .discussion-spinner {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 1;
    > span {
      padding-left: 0;
      color: white;
      left: 50%;
      top: 50%;
      position: relative;
      transform: translate(-50%, -50%);
      display: inline-block;
    }
  }
  .community-questions-left {
    max-width: 70px;
    padding-right: 20px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .community-questions-user {
      width: 36px;
      height: 36px;
      margin: 0 auto 10px auto;
      @include border-radius(50%);
      background-color: $alert-grey;
      img {
        width: 36px;
        height: 36px;
        @include border-radius(50%);
      }
    }
    .community-questions-likes {
      text-align: center;
      display: flex;
      justify-content: center;
      flex-direction: column;
      .btn-community-trash {
        &:hover {
          background: none;
          border: $community-button-grey 1px solid;
          color: $black;
        }
      }
      .comment-liked {
        background-color: $danger-color;
        border: $danger-color 1px solid;
        color: $white;
        &:hover {
          background-color: $danger-color;
          border: $danger-color 1px solid;
          color: white;
        }
        .MuiCircularProgress-colorPrimary {
          color: $white;
        }
      }
      .community-questions-likes-icon {
        margin: 10px auto;
        width: 30px;
        height: 30px;
        @include border-radius(50%);
        background-color: $alert-grey;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $tooltip-color;
        border: $text-gray-40 1px solid;
      }
      .community-questions-likes-text {
        margin-top: 10px;
        font-size: 0.75rem;
        font-family: "copperplateregular";
        color: $tooltip-color;
      }
    }
  }
  .community-questions-right {
    width: 100%;
    .community-questions-title {
      font-size: 1.125rem;
      font-family: "ralewayregular";
      color: $file-name-color;
      &:hover {
        cursor: pointer;
      }
      &.community-questions-title-details {
        color: $primary-color;
      }
    }
    .community-questions-created-info {
      span {
        color: $tooltip-color;
        font-size: 0.8rem;
        padding: 2px 12px;
        display: inline-block;
        vertical-align: middle;
        margin: 5px auto;
        border-left: $circle-border 1px solid;
        &:first-child {
          border-left: none;
          padding-left: 0px;
        }
      }
    }
    .community-questions-description {
      font-size: 1rem;
      font-family: "ralewayregular";
      padding: 5px 0;
      line-height: 160%;
    }
    .community-questions-bottom {
      background-color: $community-light-grey;
      padding: 10px;
      display: flex;
      border-radius: 10px;
      @media (max-width:960px)
        {
          flex-direction: column;
        }
      .community-questions-bottom-left {
        width: 50%;
        display: flex;
        align-items: center;
        @media (max-width:960px)
          {
            width: 100%;
          }
      }
      .community-questions-bottom-right {
        width: 50%;
        justify-content: flex-end;
        display: flex;
        align-items: center;
        @media (max-width:960px)
        {
          width: 100%;
          margin-top: 7px;
        }
        @media (max-width:600px)
        {
          .btn-community-edit
          {
            margin-left: 0;
          }
        }
        .action-active {
          color: $white;
          background-color: $danger-color;
          border: $danger-color;
        }
        .report-active {
          color: $white;
          background-color: $orange;
          border: $orange;
        }
      }
      .community-questions-button {
        border: $community-button-grey 1px solid;
        margin: 5px;
        color: $tooltip-color;
        font-size: 0.75rem;
        @include border-radius(50px);
        display: inline-flex;
        align-items: center;
        padding: 6px 10px;
        .community-questions-number {
          font-family: "copperplateregular";
          padding: 0 4px;
          font-weight: bold;
        }
        .community-questions-text {
          font-family: "ralewaybold";
          font-size: 0.7rem;
          @media (max-width:600px)
          {
            display: none;
          }
        }
      }
    }
  }
}

// community comments
.community-comments {
  .community-comments-box {
    position: relative;
    background-color: $white;
    @include border-radius(10px);
    @include box-shadow(rgba($black, 0.1), 0px, 1px, 4px);
    padding-bottom: 20px;
    .MuiTabs-flexContainer {
      justify-content: flex-end;
    }
    .community-comments-tab-labels-custom {
      display: flex;
      align-items: center;
    }
    .community-comments-number {
      color: $not-found-p;
      font-family: "copperplate-bold";
      position: absolute;
      top: 17px;
      left: 25px;
    }
    .community-comments-content {
      display: flex;
      border-bottom: rgba($secondary-color, 0.1) 1px solid;
      padding: 30px 20px;
      &:last-child {
        border-bottom: none;
      }
      .community-comments-content-left {
        max-width: 70px;
        padding-right: 20px;
        padding-bottom: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .community-comments-content-user {
          width: 36px;
          height: 36px;
          margin: 0 auto 10px auto;
          @include border-radius(50%);
          background-color: $alert-grey;
          img {
            width: 36px;
            height: 36px;
            @include border-radius(50%);
          }
        }
      }
      .community-comments-content-right {
        width: 100%;
        .community-comments-content-title {
          font-size: 1.125rem;
          font-family: "ralewayregular";
          color: $file-name-color;
        }
        .community-comments-content-created-info {
          color: $tooltip-color;
          font-size: 0.8rem;
          padding: 5px 0;
          span {
            font-size: 0.8rem;
            padding: 0px 4px;
            display: inline-block;
            vertical-align: middle;
            font-family: "copperplateregular";
          }
        }
        .community-comments-content-description {
          font-size: 1rem;
          font-family: "ralewayregular";
          padding: 5px 0;
          line-height: 160%;
        }
        .community-comments-content-bottom {
          span {
            color: $tooltip-color;
            font-size: 0.8rem;
            padding: 2px 12px;
            display: inline-block;
            vertical-align: middle;
            margin: 5px auto;
            border-left: $circle-border 1px solid;
            cursor: pointer;
            &.edit-community-style {
              &:hover {
                color: $primary-color;
              }
            }
            i {
              margin-right: 7px;
            }
            &:first-child {
              border-left: none;
              padding-left: 0px;
            }
            .default-spinner {
              margin: 0;
              display: inline-flex;
              align-items: center;
              padding-top: 0;
              padding-bottom: 0;
              padding-right: 10px;
              span {
                padding: 0 !important;
                margin: 0;
                display: inline-flex;
                align-items: center;
                .MuiCircularProgress-root {
                  color: $danger-color;
                }
              }
            }
            &.like-community-style {
              &:hover {
                color: $danger-color;
              }
            }
            &.edit-community-style {
              &:hover {
                color: $primary-color;
              }
            }
            &.report-community-style {
              &:hover {
                color: $orange;
              }
            }
            &.delete-community-style {
              &:hover {
                color: $danger-color;
              }
            }
          }
          .single-comment-liked {
            color: $danger-color !important;
            font-size: 0.8rem;
            padding: 2px 12px;
            display: inline-block;
            vertical-align: middle;
            margin: 5px auto;
            border-left: $circle-border 1px solid;
            cursor: pointer;
            background: none;
            border: none;
          }
          .single-comment-disliked {
            background: none;
            border: none;
          }
        }
        .report-active {
          color: $danger-color !important;
        }
        .report-inactive {
          color: #798897;
        }
      }
    }
    .loader-wrapper {
      position: absolute;
      border-radius: 10px;
    }
  }
}

// community add comments
.community-comments-add {
  padding: 20px 0px;
  display: flex;
  .upload-img-size {
    display: inline-flex;
    margin: 30px 0;
    .MuiButton-text {
      padding: 0 60px;
    }
    .img-filed {
      height: 50px;
    }
  }
  .community-comments-add-textbox {
    background-color: $white;
  }
  .Community-add-buttons {
    margin-top: 20px;
    text-align: right;
  }
  .MuiButton-root {
    &.submit-comment-button {
      border: $not-found-p 1px solid;
      @include border-radius(4px);
      font-size: 1rem;
      color: $white;
      background: $primary-color;
      padding: 4px 24px;
      cursor: pointer;
      text-transform: unset;
      display: inline-block;
      vertical-align: middle;
      min-width: 123px;
      font-family: "copperplate-bold";
      &:hover {
        background-color: $primary-hover-color;
      }
      height: 40px;
    }
    &.cancel-button {
      border: none;
      @include border-radius(4px);
      font-size: 1rem;
      color: $danger-color;
      background: none;
      padding: 3px 14px;
      cursor: pointer;
      text-transform: unset;
      display: inline-block;
      vertical-align: middle;
      text-decoration: underline;
      font-family: "copperplate-bold";
      &:hover {
        color: $danger-hover-color;
      }
    }
  }
  .community-comments-add-left {
    max-width: 70px;
    padding-right: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .community-comments-add-user {
      width: 36px;
      height: 36px;
      margin: 0 auto 10px auto;
      @include border-radius(50%);
      background-color: $alert-grey;
      img {
        width: 36px;
        height: 36px;
        @include border-radius(50%);
      }
    }
  }
  .community-comments-add-right {
    width: 100%;
    .MuiFormHelperText-root.Mui-error {
      color: #f44336;
      background-color: #f5f7fd;
    }
  }
}

// community tab
.community-tab {
  position: relative;
  height: 100%;
  .MuiBox-root {
    padding: 0;
  }
  .MuiTab-wrapper {
    text-transform: none;
    font-size: 1rem;
  }
  .MuiTabs-indicator
  {
    background-color:$primary-color;
  }
}

.channel-name-info {
  display: flex;
  padding: 5px 0;
  line-height: 160%;
  color: $email-color;
  span {
    &:first-child {
      padding-right: 5px;
      font-size: 1.125rem;
    }
    &:nth-child(2) {
      font-size: 0.875rem;
    }
  }
}

// Report Discussion Modal

.dialog-box {
  .MuiDialogTitle-root {
    box-shadow: none !important;
    padding: 20px 40px 10px;
  }
}

.report-discussion-container {
  h2 {
    font-family: "ralewayregular";
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 12px;
  }

  .custom-radio-group {
    .MuiRadio-colorSecondary {
      &.Mui-checked {
        color: $logo-color;
      }
    }

    .MuiIconButton-root {
      background: transparent;
    }

    .Mui-checked:hover {
      background-color: rgba($logo-color, 0.04) !important;
    }

    .MuiIconButton-colorSecondary:hover {
      background-color: rgba($logo-color, 0.04);
    }
    .MuiFormControlLabel-root {
      margin-bottom: 5px;
    }
    .MuiFormControlLabel-label {
      color: $not-found-h;
      font-family: "ralewayregular";
    }

    .MuiRadio-root {
      color: $not-found-h;
    }
  }
}
