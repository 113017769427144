.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.sign-out{
  color: #f50057 !important;
}

/* BR-786 */
/* html{
  overflow-y: hidden;
} */

.white-space{
  white-space: nowrap;
}

/* MUI ISSUE */

.position-abs{
  position: absolute !important;
  top: 8px !important;
  color: #9e9e9e !important;
  right: 32px !important;
  clip: auto !important;
}
.savings-container{
  margin: 15px 0 !important;
}
.saving-icon{
  padding: 0 5px;
  color: #000;
  cursor: pointer;
}