@import url("fonts.css");
* {
  box-sizing: border-box;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
  padding: 0px;
}
.text-center {
  text-align: center;
}

body {
  margin: 0px;
  padding: 0px;
  font-family: "ralewayregular";
  font-size: 16px;
}

.login-wrapper {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: center space-between;
  align-items: center;
  background-color: #f7f8fc;
  height: 100vh;
}

.login-bg {
  background-color: #bdbec0;
  color: #293854;
  width: 55%;
  height: 100vh;
  position: relative;
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  place-content: center flex-end;
  align-items: center;
}
.login-form {
  width: 45%;
}
.login-form h2 {
  text-align: center;
  font-size: 32px;
  color: #233b58;
  font-weight: normal;
  font-family: "robotomedium";
  padding-bottom: 60px;
}
.login-form form {
  width: 75%;
  margin: 0 auto 0 auto;
}
.login-form .form-group {
  padding-bottom: 30px;
}
.login-form label {
  display: block;
  color: #a4a4a4;
  font-size: 14px;
  padding-bottom: 10px;
}
.login-form input {
  background-color: #ffffff;
  padding: 0px 15px;
  color: #233b58;
  border: none;
  width: 100%;
  height: 50px;
  line-height: 50px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  position: relative;
}
.login-form input:focus-visible {
  outline: 1px solid #01518c;
  border-radius: 5px;
}

.forgot-password-link {
  display: inline-block;
  padding-top: 10px;
  color: #01518c;
  text-decoration: none;
  font-size: 14px;
}
.form-group {
  position: relative;
}
.toggle-password {
  position: absolute;
  top: 45px;
  right: 20px;
  color: #9ba1b3;
}
.toggle-password:hover {
  cursor: pointer;
}
.login-content img {
  max-width: 80%;
}
.features-info {
  width: 60%;
  margin: 0 auto 0 auto;
  text-align: center;
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.features-info h3 {
  font-size: 1.5rem;
  font-family: "copperplate-bold";
  font-weight: normal;
  margin-top: 30px;
}
.features-info p {
  font-size: 1.25rem;
  font-family: "copperplateregular";
}

.feature-box {
  background-color: #ffffff;
  padding: 20px;
  color: #272140;
  border: none;
  width: 100%;
  border-radius: 5px;
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: center space-between;
  align-items: center;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
}
.feature {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: center flex-start;
  align-items: center;
}
.feature-icon {
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  border-radius: 50%;
  font-size: 21px;
  margin-right: 15px;
}
.blue .feature-icon {
  background-color: rgba(120, 176, 237, 0.25);
  color: #01518c;
}
.purple .feature-icon {
  background-color: rgba(187, 119, 241, 0.15);
  color: #bb77f1;
}
.feature-text {
  flex-direction: column;
  box-sizing: border-box;
  display: flex;
  place-content: flex-start;
  align-items: flex-start;
}
.feature-text p {
  margin: 0px;
  padding: 0px;
  font-size: 16px;
  color: #272140;
  font-family: "robotomedium";
}
.feature-text span {
  margin: 0px;
  padding: 0px;
  font-size: 14px;
  color: #576e82;
}
.feature-amount {
  font-size: 16px;
  font-family: "robotobold";
}
.blue .feature-amount {
  color: #01518c;
}
.purple .feature-amount {
  color: #bb77f1;
}
.errorMsg {
  color: #cc0000;
  margin-bottom: 2px;
  font-size: 12px;
  margin-top: -30px;
}
/**** Dashboard page Started ****/
.no-pad {
  padding: 0;
}

/* .app-container */
.nav-warp {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  position: relative;
  z-index: 3;
}

.nav-warp .side-nav {
  width: 256px;
  height: 70px;
  z-index: 2;
}

.nav-warp .nav-header {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: center flex-end;
  width: calc(100vw - 256px);

  align-items: center;
  height: 70px;
  -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.nav-warp .nav-header .nav-item {
  font-size: 18px;
  line-height: 21px;
  /* height: 70px; */
  height: 100%;
  border-left: 1px solid rgba(0, 0, 0, 0.06);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
}

.nav-warp .nav-header .nav-item a {
  display: block;
  color: rgba(149, 169, 186, 0.4);
  text-decoration: none;
  /* height: 70px; */
  height: 100%;
  width: 100%;
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: center;
  align-items: center;
  cursor: pointer;
}

.nav-warp .nav-header .nav-item-dropdown {
  height: 70px;
  /* width: 200px; */
  padding: 5px 10px;
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: center;
  align-items: center;
  border-left: 1px solid rgba(0, 0, 0, 0.06);
  cursor: pointer;
}

.nav-warp .nav-header .nav-item-dropdown i {
  font-size: 14px;
  line-height: 16px;
  color: #000000;
}

.nav-warp .nav-header .nav-item-dropdown .nav-admin-options {
  box-sizing: border-box;
  display: flex;
  place-content: center space-between;
  align-items: center;
  flex-direction: row;
}

.nav-warp .nav-header .nav-item-dropdown .nav-admin-options span {
  font-family: "robotoregular";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #000000;
  margin: 0px 12px;
}

.nav-warp .nav-header .nav-item-dropdown .nav-admin-options img {
  width: 36px;
  height: 36px;
  border-radius: 50%;
}
.nav-warp .side-nav .logo-wrap {
  background: #ffffff;
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  /* place-content: center space-between; */
  align-items: center;
  padding: 5px 5px;
  height: 70px;
  position: relative;
}

.nav-warp .side-nav .logo-wrap img {
  width: auto;
  height: 50px;
  margin-left: 10px;
  transition: 0.5s;
}
.logo-size-change img {
  height: 28px !important;
  margin-left: 0px !important;
}
/* .nav-warp .side-nav .logo-wrap i {
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 21px;
  color: rgba(149, 169, 186, 0.4);
  cursor: pointer;
} */

.side-menu-arrow {
  background-color: #edf2f7;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  color: #95a9ba !important;
  font-size: 1.125rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: calc(100% - 30px);
  top: 50%;
  transform: translateY(-50%);
  transition: 0.5s;
}
.side-menu-arrow-move {
  left: 55px;
}

/* .app-container */
.container-warp {
  /* flex: 2; */
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: flex-start;
}

.side-bar-container {
  width: 300px;
  height: calc(100vh - 70px);
  background: #01518c;
  overflow-x: hidden;
  /* overflow-y: auto; */
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-flow: column;
}

.side-bar-container .side-bar-item {
  box-sizing: border-box;
  display: flex;
  place-content: center flex-start;
  align-items: center;
  flex-direction: row;
  width: 100%;
  display: block;
  text-decoration: none;
  padding: 15px 0px;
  border-left: 3px solid transparent;
  padding-left: 35px;
}

.side-bar-container .side-bar-item.active {
  background-color: #003966;
}
.side-bar-container .side-bar-item.active span {
  font-family: "copperplate-bold" !important;
}
.side-bar-container .side-bar-item.active i {
  opacity: 1;
}

.side-bar-container .side-bar-item i {
  font-style: normal;
  font-size: 16px;
  line-height: 18px;
  color: #ffffff;
  opacity: 0.2;
  margin-left: 10px;
  width: 20px;
  position: absolute;
  left: 2px;
  text-align: center;
  transition: 0.3s;
}

.side-bar-container .side-bar-item span {
  /* font-family: "robotoregular"; */
  font-family: "copperplate-light";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  letter-spacing: 0.2px;
  width: 100%;
}

.body-container {
  height: calc(100vh - 70px);
  /* width: calc(100vw - 256px); */
  width: 100%;
  align-items: center;
  overflow-x: hidden;
  overflow-y: auto;
  background: #f7f8fc;
  position: relative;
}

@media (max-width: 959px) {
  .body-container {
    width: 100%;
  }
}

.main-title {
  padding: 0px 0px 30px 0px;
}

.main-title h1 {
  font-size: 1.25rem;
  color: #01518c;
  font-family: "ralewaybold";
}

/**** Insight cards Section ****/
.insight-cards-wrapper {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: flex-start space-between;
  align-items: flex-start;
}

.insight-card {
  background-color: #ffffff;
  padding: 15px 20px;
  color: #272140;
  border: none;
  width: 100%;
  border-radius: 5px;
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: center space-between;
  align-items: center;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
  width: 23%;
}

.insight-card h6 {
  font-family: "ralewayregular";
  font-weight: normal;
  font-size: 1rem;
}
.insight-card h6 span {
  color: rgba(149, 169, 186, 0.6);
  font-size: 12px;
}
.insight-card h3 {
  font-family: "copperplate-bold";
  font-weight: normal;
  font-size: 2rem;
}
.insight-card.vendors h3 {
  color: #01518c;
}
.insight-card.vendors h3 {
  color: #01518c;
}
.insight-card.vendors h3 {
  color: #01518c;
}
.insight-card.vendors h3 {
  color: #01518c;
}
.insight-card.members h3 {
  color: #ea3d2f;
}
.insight-card.ads h3 {
  color: #27ae60;
}
.insight-card.rebates h3 {
  color: #f2994a;
}
.insight-card i {
  color: rgba(149, 169, 186, 0.4);
  font-size: 30px;
}

/**** Rebates Purchase Section ****/
.rebates-wrapper {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: flex-start space-between;
  align-items: flex-start;
  padding: 20px 0px;
}
.purchase-rebate {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: flex-start flex-start;
  align-items: flex-start;
  background-color: #ffffff;
  color: #272140;
  border: none;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
  width: 66.5%;
}
.purchase-rebate-chart {
  width: 60%;
}
.purchase-rebate-chart-title {
  padding: 10px;
  width: 100%;
  border-bottom: 1px solid rgba(149, 169, 186, 0.2);
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: flex-start space-between;
  align-items: flex-start;
}
.purchase-rebate-chart-title h2 {
  font-family: "ralewaybold";
  font-weight: normal;
  font-size: 16px;
  color: #000000;
}
.purchase-rebate-chart-title h2 span {
  font-size: 11px;
  color: #9c9c9c;
}

.purchase-rebate-info {
  border-left: 1px solid rgba(149, 169, 186, 0.2);
  width: 40%;
}
.dashboard-pie-chart {
  /* height: 200px; */
  position: relative;
  text-align: center;
}

.pie-chart {
  padding: 20px 0px;
  text-align: center;
}

.chart-info {
  padding: 41px 20px;
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: center space-between;
  align-items: center;
}
.chart-info:first-child {
  border-bottom: 1px solid rgba(149, 169, 186, 0.2);
}
.chart-info h6 {
  font-family: "ralewayregular";
  font-weight: normal;
  font-size: 0.75rem;
  color: #9c9c9c;
  text-transform: uppercase;
}
.chart-info h3 {
  font-family: "copperplate-bold";
  font-weight: normal;
  font-size: 1.5rem;
}
.chart-info i {
  font-size: 32px;
}
.chart-info small i {
  font-size: 14px;
}
.chart-info small.increase i,
.chart-info small.increase {
  color: #27ae60;
}
.chart-info.purchase h3,
.chart-info.purchase i {
  color: #01518c;
}
.chart-info.rebate h3,
.chart-info.rebate i {
  color: #f2994a;
}
.legends {
  font-size: 0.688rem;
  color: #9c9c9c;
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: center flex-end;
  align-items: center;
  padding: 5px 0px;
  font-family: "ralewayregular";
}
.legend {
  width: 20px;
  height: 3px;
  display: inline-block;
  margin: 0px 5px;
}
.legend.purchase {
  background-color: #01518c;
}
.legend.rebate {
  background-color: #f2994a;
}

.purchase-info {
  width: 30.6%;
}
.purchase-cards {
  background-color: #ffffff;
  color: #272140;
  border: none;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  padding: 20px;
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: center space-between;
  align-items: center;
}
.purchase-cards:last-child {
  margin-bottom: 0px;
}
.purchase-cards h6 {
  font-family: "ralewayregular";
  font-weight: normal;
  font-size: 0.75rem;
  color: #9c9c9c;
  text-transform: uppercase;
}
.purchase-cards h3 {
  font-family: "copperplate-bold";
  font-weight: normal;
  font-size: 1.125rem;
  color: #000000;
}

/**** Ads Section ****/
.ads-wrapper {
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: flex-start space-between;
  align-items: flex-start;
}
.ad {
  background-color: #ffffff;
  color: #272140;
  border: none;
  width: 100%;
  border-radius: 5px;
  flex-direction: row;
  box-sizing: border-box;
  display: flex;
  place-content: flex-start;
  align-items: flex-start;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
  width: 48.6%;
}

.ad img {
  width: 45%;
  height: auto;
  -webkit-border-top-left-radius: 6px;
  -webkit-border-bottom-left-radius: 6px;
  -moz-border-radius-topleft: 6px;
  -moz-border-radius-bottomleft: 6px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.ad-content {
  width: 55%;
  padding: 15px;
}
.ad-content h3 {
  font-weight: normal;
  font-size: 1rem;
  font-family: "ralewaybold";
  color: #000000;
}
.ad-content h5 {
  font-weight: normal;
  font-size: 0.875rem;
  font-family: "ralewayregular";
  color: rgba(149, 169, 186, 0.8);
}
.ad-content h4 {
  font-weight: normal;
  font-size: 0.875rem;
  font-family: "ralewaybold";
  color: #01518c;
}
.ad-content h6 {
  font-weight: normal;
  font-size: 12px;
  font-family: "robotomedium";
  color: #000000;
}
.ad-content p {
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  margin: 0px;
  padding: 9px 0px;
}
.ad-content .special {
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.4);
  border-top: rgba(149, 169, 186, 0.2) 1px solid;
  padding-top: 8px;
}
.product-details {
  padding-top: 8px;
}
