@import "../../scss/variables-mixin.scss";
.dashboard-kpi-dropdown {
  position: relative;
  .dashboard-kpi-dropdown-button {
    @include border-radius(10px);
    width: 100%;
    border: rgba($secondary-color, 0.3) 1px solid;
    font-family: "ralewayregular";
    display: flex;
    justify-content: space-between;
    text-transform: none;
    height: 2.5rem;
    .fa-sort-down {
      margin-top: -5px;
      margin-left: 30px;
    }
  }
  .dashboard-kpi-dropdown-box {
    width: auto;
    min-width: 100%;
    z-index: 2;
    left: 0 !important;
    @media (max-width: 560px) {
      max-width: 100%;
    }
  }
}

.dashboard-table {
  .table-wrapper {
    width: 100%;
    margin-top: 20px;
    .tr {
      padding: 10px 0;
      &:last-child {
        border-bottom: none;
      }
      &:first-child {
        background: #f7f8fc;
      }
    }
  }
}
.static-table-header {
  background: #f7f8fc;
  border-bottom: 1px solid #c8d4e3;
}
.static-height {
  min-height: 340px;
}
.dashboard-table-header {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  .dashboard-table-header-title {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    align-items: center;
    display: flex;
  }
  .dashboard-table-header-search,
  .dashboard-table-header-icon {
    display: flex;
    align-items: center;
    position: relative;
  }
  .dashboard-table-header-search {
    min-width: 250px;
    .MuiInput-underline::before {
      border-bottom: $circle-border 1px solid;
    }
    .MuiInput-underline:hover:not(.Mui-disabled)::before {
      border-bottom: 1px solid $text-gray-70;
    }
    .MuiInput-underline::after {
      border-bottom: 2px solid $primary-color;
    }
    .MuiInputLabel-root.Mui-focused {
      color: $primary-color;
    }
    .MuiTextField-root {
      width: 100%;
      margin-top: -16px;
    }
    .fa-search {
      position: absolute;
      right: 4px;
      top: 50%;
      transform: translateX(-50%);
      margin-top: -8px;
      color: $secondary-color;
    }
  }
}
.dashboard-table-header-icon {
  padding: 10px;
  color: $secondary-color;
  font-size: 1.125rem;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
}
.table-wrapper {
  display: block;
}
.table-wrapper-hide {
  display: none;
}
.border-divider {
  margin-top: 20px !important;
  padding-top: 20px;
  border-top: rgba($black, 0.1) 1px solid;
}
.dashboard-section {
  padding: 25px !important;
  background-color: $white;
  @include box-shadow(0px, 2px, 4px, rgba($black, 0.1));
  padding: 10px 25px;
  @include border-radius(16px);
  position: relative;
  top: -75px;
  width: 100% !important;
  margin-top: 30px !important;
}

.dashboard-table-records {
  .td {
    padding: 20px 10px !important;
  }
  .tr:nth-child(odd) {
    background: none;
  }
  .tr:nth-child(even) {
    background: #f7f8fc;
  }
}
.dashboard-link {
  text-align: right;
  padding: 20px 0 0 0;
  color: $primary-color;
  line-height: 150%;
  > span {
    cursor: pointer;
    display: inline-block;
    border-bottom: 1px solid rgba($primary-hover-color, 0);
    &:hover {
      color: $primary-hover-color;
      border-bottom: 1px solid $primary-hover-color;
    }
  }
  .fa-chevron-right {
    font-size: 0.75rem;
    padding-left: 5px;
  }
}
.dashboard-section-height {
  min-height: 220px;
}
.dashboard-ebrand-table {
  margin: 10px 12px;
  width: 100%;
  .thead {
    background: $bgColor !important;
  }
  .tr {
    padding: 5px;
  }
  .tr:nth-child(odd) {
    background: none !important;
  }
  .tr:nth-child(even) {
    background: $bgColor;
  }
  .brand-title {
    margin-top: 10px;
    text-align: center;
    padding: 20px 10px 10px 10px;
    background-color: $bgColor;
    font-weight: bold;
  }
  .brand-header {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    height: 100%;
    line-height: 140%;
    .grid-header {
      width: 100%;
      height: 50%;
      padding: 5px;
      &:first-child {
        border-bottom: #c8d4e3 1px solid;
      }
    }
  }
  .tr .th:first-child {
    .grid-header {
      border-bottom: none;
    }
  }
  .tr .td:first-child {
    text-align: left;
    @media (max-width: 1280px) {
      justify-content: flex-start;
      max-width: 100%;
    }
  }
  .td {
    text-align: center;
    justify-content: center;
    line-height: 140%;
    @media (max-width: 1280px) {
      justify-content: flex-start;
    }
  }
}
.dashboard-head-container {
  // position: relative;
  @media (max-width: 960px) {
    padding-right: 0px;
    h3 {
      padding-right: 50px;
      width: 100%;
    }
  }
}
.progress-bar-wrapper {
  margin: 0 auto;
}
.table-data-text {
  color: $black;
}

.dropdown-menulist {
  max-height: 40vh;
  overflow: auto;
}
.dropdown-memberlist{
  max-width: 400px !important;
  li{
    white-space: break-spaces !important;
    border-bottom: #edf2f7 1px solid;
    padding: 10px;
    &:last-child{
      border-bottom: none;
    }
  }
}

.ebrand-header {
  @media (width: 1280px) {
    display: none !important;
  }
}

// div:has(> .dashboard-kpi-dropdown){
//   @media (max-width: 560px) {
//     width: 100%;
//   }
// }

#poper{
  &[data-popper-reference-hidden],&[data-popper-escaped] {
    visibility: hidden !important;
    pointer-events: none !important;
  }
}
.sortDivider{
  height: 40px !important;
  border: 1px solid rgba(0, 0, 0, 0.08) !important;
}
.search-icon{
  color: rgba(0, 0, 0, 0.4);
  padding-right: 15px;
  padding-left: 5px;
}
.sort-icon{
  font-weight: 200;
  font-size: 18px;
  padding-left: 5px;
  padding-bottom: 5px;
  margin: auto 0;
}
.member-drop-down{
  width : 400px !important;
  min-width: 0 !important;
}
.member-dropdown-menu-list{
  li{
    white-space: break-spaces !important;
  }
}
.br-bropdown-responsive{
  max-width : 280px;
  .br-dropdown-label{
    max-width : 280px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}