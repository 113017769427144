@import '../../scss/variables-mixin.scss';
.container {
  .members-content {
    padding: 0px 0px 20px;
    margin-top: -30px;
    .MuiGrid-item {
      display: flex;
    }
    .card-member-box {
      @include box-shadow(0px, 0px, 10px, rgba($black, 0.2));
      @include border-radius(5px);
      width: 100%;
      .card-media-img {
        background: white;
        position: relative;
        &:hover {
          img {
            opacity: 0.4;
            transition: 0.4s;
          }
          .members-actions {
            display: inline-block;
          }
        }

        .members-actions {
          position: absolute;
          top: 10px;
          right: 10px;
          display: none;
          button {
            background: $white;
            color: $primary-color;
            width: 22px;
            height: 22px;
            min-width: 22px;
            min-height: 22px;
            @include border-radius(50%);
            font-size: 10px;
            margin-left: 4px;
            &:hover {
              background: $primary-color;
              color: $white;
            }
          }
        }
      }

      .member-title {
        color: $black;
        font-size: 1rem;
        font-family: 'ralewaysemibold';
        margin: 0;
      }
      .member-place {
        color: rgba($black, 0.4);
        font-family: 'ralewayregular';
        font-size: 0.75rem;
        margin-bottom: 10px;
      }
      .member-name {
        color: $primary-color;
        font-family: 'ralewayregular';
        font-size: 0.875rem;
        margin: 0;
      }
      .member-contact {
        color: rgba($black, 0.4);
        font-size: 0.8rem;
        font-family: 'copperplateregular';
      }
    }
  }
}
