@import "../../../../scss/variables-mixin.scss";
.fund-transfer {
  padding: 0px 20px;
  .fund-transfer-heading {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 0.875rem;
    padding: 10px 5px;
    border-bottom: $primary-color 2px solid;
    color: $primary-color;
    margin-bottom: 30px;
    display: inline-block;
  }
}
