@import "../../../scss/variables-mixin.scss";
.change-password {
  height: 100%;
  .change-password-left {
    background-color: $change-password-bgcolor;
    .details-header-img {
      top: -40%;
    }
    .user-img {
      width: 105px;
      height: 105px;
      @include border-radius(50%);
      border: $change-password-bgcolor 7px solid;
      display: flex;
      position: relative;
      background-color: $secondary-color;
      top: 40px;
      left: 50%;
      transform: translateX(-50%);
      overflow: hidden;
      img {
        object-fit: cover;
        width: 100%;
        border-radius: 50% !important;
      }
      @media(max-width: 670px) {
        width: 85px;
        height: 85px;
      }
    }
    .name-change-password {
      font-size: 16px;
      text-align: center;
      font-weight: bold;
    }
    .email-change-password {
      color: $not-found-p;
      text-align: center;
      font-weight: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0px 2px;
      font-size: 14px;
    }
    .menu-change-password {
      .menu-change-password-list {
        margin: 40px auto;
        .menu-change-password-content {
          padding: 12px 30px;
          span {
            display: inline-block;
            vertical-align: middle;
            font-size: 16px;
            color: $grey;
            padding: 5px;
          }
          i {
            display: inline-block;
            vertical-align: middle;
            font-size: 18px;
            color: rgba($secondary-color, 0.8);
            padding: 5px 10px;
          }
          &:hover {
            background-color: $white;
            cursor: pointer;
            span,
            i {
              color: $primary-color;
            }
          }
          &.menu-change-password-active {
            background-color: $white;
            span,
            i {
              color: $primary-color;
            }
          }
          &.change-password-logout {
            span {
              color: $danger-color;
            }
            i {
              color: rgba($danger-color, 0.5);
            }
            &:hover {
              i {
                color: rgba($danger-color, 1);
              }
            }
          }
        }
      }
    }
  }
  .change-password-right {
    padding: 20px 30px;
    .change-password-tab-head {
      padding: 0 20px;

      .Mui-selected {
        color: $primary-color;
        font-weight: bold;
      }
      .MuiTabs-indicator {
        background-color: $primary-color;
      }
      .MuiTab-textColorInherit {
        text-transform: unset !important;
      }
    }
  }
  .change-password-settings {
    padding-top: 30px;
    .text-box {
      margin-bottom: 40px;
    }
  }
  .general-account-settings {
    padding-top: 30px;
    .text-box {
      margin-bottom: 40px;
    }
  }
  .change-password-form {
    padding-top: 30px;
  }
  .w-50 {
    width: 50% !important;
    padding-right: 20px !important;
    @media (max-width: 600px){
      width: 100% !important;
      padding-right: 0px !important;
    }
  }
}
